<!-- CrossCard.vue -->
<template>
  <div
    :class="
      !isMobile
        ? selectable
          ? 'card card-second'
          : 'card'
        : selectable
        ? 'card-mobile card-second'
        : 'card-mobile'
    "
  >
    <div class="radio-label-container">
      <input
        v-if="selectable"
        type="radio"
        :id="id"
        :name="name"
        :value="value"
        class="radioBtn"
        @change="handleRadioSelect"
      />
      <div class="radio-text-alignement">
        <label :for="id" class="cardTitle">{{ label }}</label>
        <div class="cardSubtitle">
          <span>{{ subtitle }}</span>
          <span>{{ address }}</span>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div style="margin: 3rem 2rem; text-align: center; vertical-align: middle">
      <img :src="img" />
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, getCurrentInstance, ref } from "vue";

const props = defineProps({
  selectable: Boolean,
  id: String,
  name: String,
  value: String,
  label: String,
  subtitle: String,
  address: String,
  description: String,
  img: String,
});
const isMobile = ref(false);
const { config } = getCurrentInstance().appContext;

isMobile.value = config.globalProperties.$IsMobile();

const emit = defineEmits(["radio-selected"]);

const handleRadioSelect = () => {
  emit("radio-selected", props.value);
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/CrossConnect.scss";

.radioBtn {
  min-width: 24px;
  min-height: 24px;
  margin-right: 2.5rem;
  opacity: 1;
  margin-bottom: 2.5rem;
  background: #032e5f 0% 0% no-repeat padding-box;
}

.radio-text-alignement {
  display: flex;
  flex-direction: column;
}

.card-second {
  margin: 0 0rem !important;
}

img {
  max-height: 8rem;
  max-width: 6rem;
}
</style>
