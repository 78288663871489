<template>
  <div id="Users">
    <MyBanner
      :text="$t('banner.accessDcPage')"
      v-if="$t('banner.accessDcPage') != ''"
    />
    <MyHeaderPage
      :title="$t('reports.historical.title')"
      :subtitle="$t('reports.historical.subTitle')"
    />
    <br /><br />
    <div id="TicketsList">
      <div class="listTicket">
        <!-- FILTRO -->
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--6-col">
            <div class="filterTickets">
              <div class="contentInputFilter">
                <div class="contentIcon">
                  <span class="material-icons">search</span>
                </div>
                <div class="contentInput">
                  <input
                    type="text"
                    :placeholder="$t('actions.text_search_placeholder')"
                    v-model="searchString"
                    @keyup="searchByString"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--3-col" v-if="false">
            <div class="cdl-input" style="margin-top: 0">
              <input
                type="date"
                name="dateFrom"
                class="not-empty"
                v-model="dateFrom"
                style="background-color: white"
                @change="getList()"
              />
              <label>{{ $t("reports.filter.dateFrom") }}</label>
              <span
                class="error"
                v-if="this.v$.dateFrom.$invalid && this.v$.dateFrom.$dirty"
              >
                {{ $t("reports.filter.dateFrom") }}
                {{ $t("reports.filter.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--3-col" v-if="false">
            <div class="cdl-input" style="margin-top: 0">
              <input
                type="date"
                name="dateTo"
                class="not-empty"
                v-model="dateTo"
                style="background-color: white"
                @change="getList()"
              />
              <label>{{ $t("reports.filter.dateTo") }}</label>
              <span
                class="error"
                v-if="this.v$.dateTo.$invalid && this.v$.dateTo.$dirty"
              >
                {{ $t("reports.filter.dateTo") }}
                {{ $t("reports.filter.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <p class="title" v-html="$t('reports.historical.moreTickets')"></p>
          </div>
        </div>
        <!-- FINE FILTRO -->
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <br />
            <table>
              <thead>
                <tr>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.historical.col.colOne") }}
                  </th>
                  <th>{{ $t("reports.historical.col.colTwo") }}</th>
                  <th>{{ $t("reports.historical.col.colThree") }}</th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.historical.col.colFour") }}
                  </th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.historical.col.colFive") }}
                  </th>
                  <th v-if="!this.$IsMobile()"></th>
                </tr>
              </thead>
              <tbody
                v-if="
                  !this.$IsMobile() && this.viewLoader && itemFilter.length > 0
                "
              >
                <tr
                  v-for="(item, keyItem) in this.itemFilter"
                  :key="keyItem"
                  :class="{ odd: keyItem % 2 }"
                >
                  <td>{{ item.ticket_esteso }}</td>
                  <td>{{ $t("reports.historical.type." + item.tipologia) }}</td>
                  <td>{{ item.data_apertura }}</td>
                  <td>{{ item.data_ultimo_aggiornamento }}</td>
                  <td>{{ item.nominativo }}</td>
                  <td v-if="item.tipologia != 'Feedback'">
                    <div class="cdlButton" @click="getDetails(item)">
                      {{ $t("tickets.details") }}
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody
                v-else-if="
                  this.$IsMobile() && this.viewLoader && itemFilter.length > 0
                "
              >
                <MobileRow
                  v-for="(item, keyItem) in this.itemFilter"
                  :key="keyItem"
                  :cols="['tipologia', 'data_apertura']"
                  :item="item"
                >
                  <template #url-label>
                    <div class="cdlButton" @click="getDetails(item)">
                      {{ $t("tickets.details") }}
                    </div>
                  </template></MobileRow
                >
              </tbody>
              <tbody v-if="items.length == 0 && viewLoader">
                <tr>
                  <td colspan="4">
                    <span class="noTickets">{{ $t("reports.noResult") }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <MyLoading :viewLoader="this.viewLoader" type="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="MyModal" v-if="viewModal" style="padding-top: 20px">
    <div class="contentModal open success historical" style="width: 50%">
      <span
        class="material-icons closeOrder"
        id="closeModalX"
        @click="ctrlModal()"
        >close</span
      >
      <div v-if="modal.type == 'Access request'">
        <h3>{{ $t("reports.historical.modal.access.title") }}</h3>
        <div>
          <table>
            <tbody>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.ticket_esteso") }}
                </td>
                <td>{{ modal.ticket_esteso }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.access.data_richiesta") }}
                </td>
                <td>{{ modal.data_richiesta }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.access.dc") }}
                </td>
                <td>{{ modal.dc }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.access.data_arrivo") }}
                </td>
                <td>{{ modal.data_arrivo }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.access.data_uscita") }}
                </td>
                <td>{{ modal.data_uscita }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.access.apparati") }}
                </td>
                <td>{{ modal.apparati }}</td>
              </tr>
              <tr v-if="modal.meeting_room_pax > 0">
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.access.meeting_room_pax") }}
                </td>
                <td>{{ modal.meeting_room_pax }}</td>
              </tr>
              <tr v-if="modal.meeting_room_pax > 0">
                <td class="text" style="text-align: right">
                  {{
                    $t("reports.historical.modal.access.meeting_room_pax_time")
                  }}
                </td>
                <td>
                  {{
                    $t(
                      "reports.historical.modal.access.meeting_room_paxtime_value",
                      {
                        from: modal.meeting_room_from,
                        to: modal.meeting_room_to,
                      }
                    )
                  }}
                </td>
              </tr>
              <tr v-if="modal.traka_rooms">
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.access.traka_message") }}
                </td>
                <td>{{ modal.traka_message }}</td>
              </tr>
              <tr v-if="modal.traka_rooms">
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.access.traka_rooms") }}
                </td>
                <td>{{ modal.traka_rooms }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.access.messaggio") }}
                </td>
                <td v-html="modal.messaggio"></td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.customer_ref") }}
                </td>
                <td>{{ modal.customer_ref }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.email_ref") }}
                </td>
                <td>{{ modal.email_ref }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.telefono_ref") }}
                </td>
                <td>{{ modal.telefono_ref }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="modal.type == 'Incident'">
        <h3>{{ $t("reports.historical.modal.incident.title") }}</h3>
        <div>
          <table>
            <tbody>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.ticket_esteso") }}
                </td>
                <td>{{ modal.ticket_esteso }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.data_apertura") }}
                </td>
                <td>{{ modal.data_apertura }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.incident.area") }}
                </td>
                <td>{{ modal.area }}</td>
              </tr>
              <tr v-if="modal.room">
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.incident.room") }}
                </td>
                <td>{{ modal.room }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.messaggio") }}
                </td>
                <td v-html="modal.messaggio"></td>
              </tr>
              <tr v-if="modal.serialnumber">
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.incident.serialnumber") }}
                </td>
                <td v-html="modal.serialnumber"></td>
              </tr>
              <tr v-if="modal.transito_ip">
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.incident.transito_ip") }}
                </td>
                <td v-html="modal.transito_ip"></td>
              </tr>
              <tr v-if="modal.id_apparato">
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.incident.id_apparato") }}
                </td>
                <td v-html="modal.id_apparato"></td>
              </tr>
              <tr v-if="modal.tipologia">
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.incident.tipologia") }}
                </td>
                <td v-html="modal.tipologia"></td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.customer_ref") }}
                </td>
                <td>{{ modal.customer_ref }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.email_ref") }}
                </td>
                <td>{{ modal.email_ref }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.telefono_ref") }}
                </td>
                <td>{{ modal.telefono_ref }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="modal.type == 'Remote hands'">
        <h3>{{ $t("reports.historical.modal.rh.title") }}</h3>
        <div>
          <table>
            <tbody>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.ticket_esteso") }}
                </td>
                <td>{{ modal.ticket_esteso }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.data_apertura") }}
                </td>
                <td>{{ modal.data_apertura }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.incident.room") }}
                </td>
                <td>{{ modal.room }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.rh.rack") }}
                </td>
                <td>{{ modal.rack }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.rh.summary") }}
                </td>
                <td v-html="modal.messaggio"></td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.rh.liv_tecnico") }}
                </td>
                <td v-html="modal.liv_tecnico"></td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.rh.liv_urgent") }}
                </td>
                <td v-html="modal.liv_urgent"></td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.rh.po_number") }}
                </td>
                <td v-html="modal.po_number"></td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.rh.activities") }}
                </td>
                <td v-html="modal.activities"></td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.customer_ref") }}
                </td>
                <td>{{ modal.customer_ref }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.email_ref") }}
                </td>
                <td>{{ modal.email_ref }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.telefono_ref") }}
                </td>
                <td>{{ modal.telefono_ref }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="modal.type == 'Delivery'">
        <h3>{{ $t("reports.historical.modal.delivery.title") }}</h3>
        <div>
          <table>
            <tbody>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.ticket_esteso") }}
                </td>
                <td>{{ modal.ticket_esteso }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.data_apertura") }}
                </td>
                <td>{{ modal.data_apertura }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.delivery.dc") }}
                </td>
                <td>{{ modal.dc }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.delivery.data_arrivo") }}
                </td>
                <td>{{ modal.data_arrivo.split(" ")[0] }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.delivery.spedita_da") }}
                </td>
                <td v-html="modal.spedita_da"></td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.delivery.bancali") }}
                </td>
                <td v-html="modal.bancali"></td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.delivery.numero_pacchi") }}
                </td>
                <td v-html="modal.numero_pacchi"></td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.delivery.peso") }}
                </td>
                <td v-html="modal.peso"></td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.delivery.descrizione") }}
                </td>
                <td v-html="modal.descrizione"></td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.delivery.maggiori_info") }}
                </td>
                <td v-html="modal.maggiori_info"></td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.customer_ref") }}
                </td>
                <td>{{ modal.customer_ref }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.email_ref") }}
                </td>
                <td>{{ modal.email_ref }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.telefono_ref") }}
                </td>
                <td>{{ modal.telefono_ref }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="modal.type == 'Cross connect'">
        <h3>{{ $t("reports.historical.modal.xc.title") }}</h3>
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <table>
              <tbody>
                <tr>
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.ticket_esteso") }}
                  </td>
                  <td>{{ modal.ticket_esteso }}</td>
                </tr>
                <tr>
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.data_apertura") }}
                  </td>
                  <td>{{ modal.data_apertura }}</td>
                </tr>
                <tr v-if="modal.redundancy > 0">
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.xc.redundancy") }}
                  </td>
                  <td>
                    <div
                      @click="
                        this.ctrlModal();
                        getDetails({
                          tipologia: 'Cross connect',
                          id: modal.redundancy,
                        });
                      "
                      style="cursor: pointer"
                    >
                      <u>{{
                        $t("reports.historical.modal.xc.redundancyCta")
                      }}</u>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <table style="width: 100%">
              <tbody>
                <tr>
                  <td class="text" colspan="2"><strong>A-END</strong></td>
                </tr>
                <tr v-if="modal.patch_type">
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.xc.patch_type") }}
                  </td>
                  <td>{{ modal.patch_type }}</td>
                </tr>
                <tr>
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.xc.room") }}
                  </td>
                  <td>{{ modal.room }}</td>
                </tr>
                <tr>
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.xc.rack") }}
                  </td>
                  <td>{{ modal.rack }}</td>
                </tr>
                <tr>
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.xc.panel_level") }}
                  </td>
                  <td>{{ modal.panel_level }}</td>
                </tr>
                <tr>
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.xc.port") }}
                  </td>
                  <td>{{ modal.port }}</td>
                </tr>
                <tr>
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.xc.po_number") }}
                  </td>
                  <td>{{ modal.po_number }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <table style="width: 100%">
              <tbody>
                <tr>
                  <td class="text" colspan="2"><strong>B-END</strong></td>
                </tr>
                <tr v-if="modal.loa">
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.xc.loa") }}
                  </td>
                  <td>{{ modal.loa }}</td>
                </tr>
                <tr v-else>
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.xc.loa") }}
                  </td>
                  <td>{{ $t("reports.historical.modal.xc.noLoa") }}</td>
                </tr>
                <tr v-if="modal.building">
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.xc.building") }}
                  </td>
                  <td>{{ modal.building }}</td>
                </tr>
                <tr v-if="modal.wing">
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.xc.wing") }}
                  </td>
                  <td>{{ modal.wing }}</td>
                </tr>
                <tr v-if="modal.floor">
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.xc.floor") }}
                  </td>
                  <td>{{ modal.floor }}</td>
                </tr>
                <tr v-if="modal.muffola">
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.xc.muffola") }}
                  </td>
                  <td>{{ modal.muffola }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <table>
              <tbody>
                <tr>
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.xc.attivazione") }}
                  </td>
                  <td>{{ modal.attivazione }}</td>
                </tr>
                <tr>
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.xc.canone") }}
                  </td>
                  <td>{{ modal.canone }}</td>
                </tr>
                <tr>
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.customer_ref") }}
                  </td>
                  <td>{{ modal.customer_ref }}</td>
                </tr>
                <tr>
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.email_ref") }}
                  </td>
                  <td>{{ modal.email_ref }}</td>
                </tr>
                <tr>
                  <td class="text" style="text-align: right">
                    {{ $t("reports.historical.modal.telefono_ref") }}
                  </td>
                  <td>{{ modal.telefono_ref }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="modal.type == 'Change request'">
        <h3>{{ $t("reports.historical.modal.cr.title") }}</h3>
        <div>
          <table>
            <tbody>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.ticket_esteso") }}
                </td>
                <td>{{ modal.ticket_esteso }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.data_apertura") }}
                </td>
                <td>{{ modal.data_apertura }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.incident.area") }}
                </td>
                <td>{{ modal.area }}</td>
              </tr>
              <tr v-if="modal.room">
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.incident.room") }}
                </td>
                <td>{{ modal.room }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.messaggio") }}
                </td>
                <td v-html="modal.messaggio"></td>
              </tr>
              <tr v-if="modal.serialnumber">
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.incident.serialnumber") }}
                </td>
                <td v-html="modal.serialnumber"></td>
              </tr>
              <tr v-if="modal.transito_ip">
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.incident.transito_ip") }}
                </td>
                <td v-html="modal.transito_ip"></td>
              </tr>
              <tr v-if="modal.id_apparato">
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.incident.id_apparato") }}
                </td>
                <td v-html="modal.id_apparato"></td>
              </tr>
              <tr v-if="modal.tipologia">
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.incident.tipologia") }}
                </td>
                <td v-html="modal.tipologia"></td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.customer_ref") }}
                </td>
                <td>{{ modal.customer_ref }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.email_ref") }}
                </td>
                <td>{{ modal.email_ref }}</td>
              </tr>
              <tr>
                <td class="text" style="text-align: right">
                  {{ $t("reports.historical.modal.telefono_ref") }}
                </td>
                <td>{{ modal.telefono_ref }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="contentButton">
        <button class="cdlButton" @click="ctrlModal()" style="width: 100%">
          {{ $t("reports.historical.closeModal") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import MyLoading from "@/components/MyLoading.vue";
import MobileRow from "@/components/Tables/MobileRow.vue";
import "@/assets/scss/TicketsList.scss";
import moment from "moment";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "CHistorical",
  components: {
    MyBanner,
    MyHeaderPage,
    MyLoading,
    MobileRow,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      dateFrom: { required },
      dateTo: { required },
    };
  },
  data() {
    return {
      viewLoader: false,
      items: [],
      itemFilter: [],
      dateFrom: moment().subtract(6, "months").format("DD-MM-YYYY"),
      dateTo: moment().format("DD-MM-YYYY"),
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      searchString: "",
      viewModal: false,
      modal: {
        type: "",
        ticket_esteso: 0,
        data_arrivo: 0,
        data_uscita: 0,
        meeting_room_from: 0,
        meeting_room_to: 0,
        meeting_room_pax: 0,
        traka_rooms: 0,
        traka_message: 0,
        messaggio: 0,
        data_apertura: 0,
        customer_ref: "",
        email_ref: "",
        telefono_ref: "",
        dc: "",
        apparati: "no",
        area: null,
        room: null,
        serialnumber: null,
        transito_ip: 0,
        id_apparato: 0,
        tipologia: "",

        richiesta: null,
        rack: null,
        liv_tecnico: null,
        liv_urgent: null,
        po_number: null,
        activities: null,

        descrizione: null,
        maggiori_info: null,
        peso: null,
        codice_spedizione: null,
        spedita_da: null,
        bancali: null,
        numero_pacchi: null,

        attivazione: null,
        canone: null,
        panel_level: null,
        port: null,
        redundancy: null,

        loa: null,
        building: null,
        floor: null,
        wing: null,
        muffola: null,
      },
    };
  },
  methods: {
    async getList() {
      const result = await this.v$.$validate();
      if (result) {
        const self = this;
        this.axios
          .get(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "tickets/v1/otrs",
            { headers: this.he }
          )
          .then((rls) => {
            this.items = rls.data;
            this.itemFilter = this.items;
            this.viewLoader = true;
          })
          .catch(function (err) {
            self.viewLoader = true;
            console.log(err);
          });
      }
    },
    searchByString() {
      this.itemFilter = [];
      if (this.searchString.length >= 3) {
        let elm = this.searchString.toLowerCase();
        this.items.forEach((item) => {
          let ticket_esteso = item.ticket_esteso
            ? item.ticket_esteso.toLowerCase().includes(elm)
            : false;
          let tipologia = item.tipologia
            ? item.tipologia.toLowerCase().includes(elm)
            : false;
          let data_apertura = item.data_apertura
            ? item.data_apertura.toLowerCase().includes(elm)
            : false;
          let data_ultimo_aggiornamento = item.data_ultimo_aggiornamento
            ? item.data_ultimo_aggiornamento.toLowerCase().includes(elm)
            : false;
          let nominativo = item.nominativo
            ? item.nominativo.toLowerCase().includes(elm)
            : false;
          if (
            ticket_esteso ||
            tipologia ||
            data_apertura ||
            data_ultimo_aggiornamento ||
            nominativo
          ) {
            this.itemFilter.push(item);
          }
        });
      } else {
        this.itemFilter = this.items;
      }
    },
    getDetails(item) {
      this.resetFields();
      if (item.tipologia == "Feedback") {
        return;
      }

      let url = "";
      switch (item.tipologia) {
        case "Access request":
          url = "access";
          break;
        case "Incident":
          url = "incident";
          break;
        case "Remote hands":
          url = "remote-hands";
          break;
        case "Change request":
          url = "change-request";
          break;
        case "Delivery":
          url = "shipping";
          break;
        case "Cross connect":
          url = "cross-connect/a-end";
          break;
      }
      const self = this;
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "tickets/v1/otrs/" +
            url +
            "/" +
            item.id,
          { headers: this.he }
        )
        .then((rls) => {
          this.modal.type = item.tipologia;
          this.modal.ticket_esteso = rls.data.ticket_esteso;
          this.modal.customer_ref = rls.data.customer_ref;
          this.modal.email_ref = rls.data.email_ref;
          this.modal.telefono_ref = rls.data.telefono_ref;
          this.modal.data_richiesta = rls.data.data_richiesta;
          this.modal.data_apertura = rls.data.data_apertura;
          this.modal.messaggio = rls.data.messaggio;
          this.modal.dc = rls.data.dc;
          switch (item.tipologia) {
            case "Access request":
              this.modal.data_arrivo = rls.data.data_arrivo;
              this.modal.data_uscita = rls.data.data_uscita;
              this.modal.meeting_room_from = rls.data.meeting_room_from;
              this.modal.meeting_room_to = rls.data.meeting_room_to;
              this.modal.meeting_room_pax = rls.data.meeting_room_pax;
              this.modal.traka_rooms = rls.data.traka_rooms;
              this.modal.traka_message = rls.data.traka_message;
              this.modal.apparati = rls.data.apparati;
              break;
            case "Incident":
              this.modal.area = rls.data.area;
              this.modal.room = rls.data.room;
              this.modal.serialnumber = rls.data.serialnumber;
              this.modal.transito_ip = rls.data.transito_ip;
              this.modal.id_apparato = rls.data.id_apparato;
              this.modal.tipologia = rls.data.tipologia;
              break;
            case "Remote hands":
              this.modal.room = rls.data.room;
              this.modal.serialnumber = rls.data.serialnumber;
              this.modal.transito_ip = rls.data.transito_ip;
              this.modal.id_apparato = rls.data.id_apparato;
              this.modal.richiesta = rls.data.richiesta;
              this.modal.rack = rls.data.rack;
              this.modal.liv_tecnico = rls.data.liv_tecnico;
              this.modal.liv_urgent = rls.data.liv_urgent;
              this.modal.po_number = rls.data.po_number;
              this.modal.activities = rls.data.activities;
              break;
            case "Change request":
              this.modal.area = rls.data.area;
              this.modal.room = rls.data.room;
              this.modal.serialnumber = rls.data.serialnumber;
              this.modal.transito_ip = rls.data.transito_ip;
              this.modal.id_apparato = rls.data.id_apparato;
              this.modal.tipologia = rls.data.tipologia;

              break;
            case "Delivery":
              this.modal.data_apertura = rls.data.data_richiesta;
              this.modal.descrizione = rls.data.descrizione;
              this.modal.maggiori_info = rls.data.maggiori_info;
              this.modal.peso = rls.data.peso;
              this.modal.codice_spedizione = rls.data.codice_spedizione;
              this.modal.data_arrivo = rls.data.data_arrivo;
              this.modal.spedita_da = rls.data.spedita_da;
              this.modal.bancali = rls.data.bancali;
              this.modal.numero_pacchi = rls.data.numero_pacchi;
              break;
            case "Cross connect":
              this.modal.attivazione = rls.data.attivazione;
              this.modal.canone = rls.data.canone;
              this.modal.panel_level = rls.data.panel_level;
              this.modal.po_number = rls.data.po_number;
              this.modal.port = rls.data.port;
              this.modal.room = rls.data.room;
              this.modal.rack = rls.data.rack;
              this.modal.redundancy = rls.data.redundancy;

              this.axios
                .get(
                  this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
                    "tickets/v1/otrs/cross-connect/b-end/" +
                    item.id,
                  { headers: this.he }
                )
                .then((b_end) => {
                  this.modal.loa = b_end.data.documento;
                  this.modal.building = b_end.data.building;
                  this.modal.floor = b_end.data.floor;
                  this.modal.wing = b_end.data.wing;
                  this.modal.muffola = b_end.data.muffola;
                });
              break;
          }
          this.ctrlModal();
          this.viewLoader = true;
        })
        .catch(function (err) {
          self.viewLoader = true;
          console.log(err);
        });
    },
    resetFields() {
      this.modal.type = "";
      this.modal.ticket_esteso = 0;
      this.modal.data_arrivo = 0;
      this.modal.data_uscita = 0;
      this.modal.meeting_room_from = 0;
      this.modal.meeting_room_to = 0;
      this.modal.meeting_room_pax = 0;
      this.modal.traka_rooms = 0;
      this.modal.traka_message = 0;
      this.modal.messaggio = 0;
      this.modal.data_apertura = 0;
      this.modal.customer_ref = "";
      this.modal.email_ref = "";
      this.modal.telefono_ref = "";
      this.modal.dc = "";
      this.modal.apparati = "no";
      this.modal.area = null;
      this.modal.room = null;
      this.modal.serialnumber = null;
      this.modal.transito_ip = 0;
      this.modal.id_apparato = 0;
      this.modal.tipologia = "";

      this.modal.richiesta = null;
      this.modal.rack = null;
      this.modal.liv_tecnico = null;
      this.modal.liv_urgent = null;
      this.modal.po_number = null;
      this.modal.activities = null;

      this.modal.descrizione = null;
      this.modal.maggiori_info = null;
      this.modal.peso = null;
      this.modal.codice_spedizione = null;
      this.modal.spedita_da = null;
      this.modal.bancali = null;
      this.modal.numero_pacchi = null;

      this.modal.attivazione = null;
      this.modal.canone = null;
      this.modal.panel_level = null;
      this.modal.port = null;
      this.modal.redundancy = null;

      this.modal.loa = null;
      this.modal.building = null;
      this.modal.floor = null;
      this.modal.wing = null;
      this.modal.muffola = null;
    },
    ctrlModal() {
      this.viewModal = !this.viewModal;

      if (this.viewModal) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        document.getElementsByTagName("body")[0].classList.add("overflow");
      } else {
        document.getElementsByTagName("body")[0].classList.remove("overflow");
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
