<template>
  <div id="PageTable">
    <MyBanner :text="$t('banner.invoices')" />
    <MyHeaderPage
      :title="$t('invoices.title')"
      :subtitle="$t('invoices.subTitle')"
    />
    <br />

    <div class="contentPage">
      <div class="mdl-grid" style="align-items: baseline">
        <div class="mdl-cell mdl-cell--4-col">
          <div class="cdl-input">
            <input type="date" class="not-empty" v-model="dateFrom" />
            <label>{{ $t("filter.from") }}</label>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <div class="cdl-input">
            <input type="date" class="not-empty" v-model="dateTo" />
            <label>{{ $t("filter.to") }}</label>
          </div>
        </div>
        <div
          v-if="!IsMobile"
          class="mdl-cell mdl-cell--2-col mdl-cell--1-offset"
        >
          <div class="cdlButtonGreen" @click="getInvoices()">
            {{ $t("filter.filter") }}
          </div>
        </div>
        <div v-else class="mdl-cell mdl-cell--12-col-phone">
          <div
            class="cdlButtonGreen"
            style="width: auto"
            @click="getInvoices()"
          >
            {{ $t("filter.filter") }}
          </div>
        </div>
      </div>

      <List
        :list="this.invoices"
        :listPaginate="this.listPaginate"
        :view-loader="this.viewLoader"
        :view-error="this.viewError"
        :total-count="this.total_page"
      />
    </div>
  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import moment from "moment";
import { useFilterStore } from "@/stores/FilterStore";
import List from "@/components/Invoice/List.vue";

export default {
  name: "InvoicePage",
  components: {
    MyBanner,
    MyHeaderPage,
    List,
  },
  data() {
    return {
      IsMobile: this.$IsMobile(),
      dateFrom: moment().subtract(6, "months").format("DD-MM-YYYY"),
      dateTo: moment().format("DD-MM-YYYY"),
      invoices: [],
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      viewLoader: true,
      total_page: 0,
      current_page: 1,
      listPaginate: [],
      itemForPage: 10,
    };
  },
  methods: {
    setFilter() {
      const filterStore = useFilterStore();
      filterStore.invoices = {
        from: this.dateFrom,
        to: this.dateTo,
      };
      this.getInvoices();
    },
    getInvoices() {
      this.viewLoader = false;
      const self = this;
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "invoices/v1/invoices?from=" +
            this.dateFrom +
            "&to=" +
            this.dateTo,
          { headers: this.he }
        )
        .then((rls) => {
          this.invoices = rls.data;
          this.listPaginate = this.invoices.slice(0, this.itemForPage);
          this.total_page = Math.ceil(this.invoices.length / this.itemForPage);
          this.viewLoader = true;
        })
        .catch(function (err) {
          console.log(err);
          self.viewLoader = true;
        });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    const filterStore = useFilterStore();
    if (!filterStore.invoices.from || !filterStore.invoices.to) {
      this.setFilter();
      return;
    } else if (
      filterStore.invoices.from != this.dateFrom ||
      filterStore.invoices.to != this.dateTo
    ) {
      this.dateFrom = filterStore.invoices.from;
      this.dateTo = filterStore.invoices.to;
      this.getInvoices();
      return;
    }
    this.setFilter();
  },
};
</script>
