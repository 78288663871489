<template>
  <div id="MyCart" :class="{ active: view, close: !view }">
    <span class="material-icons closeCart" @click="closeCart()">close</span>
    <h3>{{ $t("cart.title") }}</h3>
    <p class="totalItems" v-if="carts.length > 0">
      {{ carts.length }} {{ $t("cart.order", carts.length) }}
    </p>
    <p v-else><br /></p>
    <hr class="hrStyle" />
    <hr class="hrStyle last" />
    <div class="contentItems" v-if="carts.length > 0">
      <div class="headerOrder">
        <div>
          <p class="material-icons receipt_long">receipt_long</p>
        </div>
        <div class="contentTitleCartComponent">
          <p class="orderName">{{ $t("cart.orderName") }}</p>
        </div>
        <div class="contentButtonNewOrderComponent" v-if="false">
          <span
            class="material-icons CdlIcon"
            @click="newOrder()"
            v-tooltip:bottom.tooltip="$t('cart.addOrder')"
            >add</span
          >
        </div>
      </div>

      <div v-if="viewLoaderCart">
        <div
          class="itemCart itemCartFirst"
          v-for="(cart, key) in carts.filter(
            (c) => c.network_access_details != null || undefined
          )"
          :key="key"
          :class="[
            getClassKit(cart.network_access_details.technology),
            key > 0 ? 'marginTop' : '',
          ]"
        >
          <div class="mdl-grid">
            <div
              class="mdl-cell mdl-cell--2-col mdl-cell--1-col-tablet mdl-cell--1-col-phone"
              style="text-align: center"
            >
              <img
                :src="
                  cart.network_access_details
                    ? getIcon(cart.network_access_details.technology)
                    : getIcon()
                "
                alt="technology"
              />
              <br /><br />
              <span class="material-icons CdlIcon noOpacityNoCursor"
                >location_on</span
              >
            </div>
            <div
              class="mdl-cell mdl-cell--7-col mdl-cell--3-col-tablet mdl-cell--3-col-phone"
            >
              <span class="label">{{
                $t("checkCoverageSummary.supplyActivation")
              }}</span>
              <p class="value">{{ cart.title }}</p>
              <span class="label">{{
                $t("checkCoverage.detailLabelAddress")
              }}</span>
              <p class="value">
                {{ getAddress(cart.network_access_details.address) }}
              </p>
            </div>
            <div
              class="mdl-cell mdl-cell--2-col mdl-cell--12-col-tablet mdl-cell--12-col-phone contentMiddle"
            >
              <span
                :id="['item-cart-panel-' + key]"
                class="material-icons CdlIcon CdlIconReverse"
                @click="getItem(cart)"
                style="margin-right: 1.25rem"
                v-tooltip:bottom.tooltip="$t('cart.edit')"
                >edit</span
              >
              <span
                class="material-icons CdlIcon CdlIconReverse"
                @click="modalRemoveItem(cart.id)"
                style="margin-right: 1.25rem"
                v-tooltip:bottom.tooltip="$t('cart.deleteSolution')"
                >delete</span
              >
            </div>
            <div class="mdl-cell mdl-cell--2-col"></div>
            <div class="mdl-cell mdl-cell--8-col" v-if="false">
              <hr />
            </div>
            <div class="mdl-cell mdl-cell--2-col" v-if="false"></div>
            <div class="mdl-cell mdl-cell--2-col" v-if="false"></div>
            <div class="mdl-cell mdl-cell--8-col" v-if="false">
              <div class="mdl-grid">
                <div
                  class="mdl-cell mdl-cell--9-col mdl-cell--2-col-tablet mdl-cell--2-col-phone"
                >
                  <p class="detailOne">{{ $t("checkCoverage.priceTantum") }}</p>
                </div>
                <div
                  class="mdl-cell mdl-cell--3-col mdl-cell--2-col-tablet mdl-cell--2-col-phone"
                >
                  <p class="detailThree">
                    €{{ getPrice(cart.base_price.nrc) }}
                  </p>
                  <p class="detailTwo" v-if="false">
                    {{ $t("checkCoverage.tantum") }}
                  </p>
                </div>
                <div
                  class="mdl-cell mdl-cell--9-col mdl-cell--2-col-tablet mdl-cell--2-col-phone"
                >
                  <p class="detailOne">{{ $t("checkCoverage.priceMonth") }}</p>
                </div>
                <div
                  class="mdl-cell mdl-cell--3-col mdl-cell--2-col-tablet mdl-cell--2-col-phone"
                >
                  <p class="detailThree">
                    €{{ getPrice(cart.base_price.mrc) }}
                  </p>
                  <p class="detailTwo" v-if="false">
                    {{ $t("checkCoverage.forMonth") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="mdl-cell mdl-cell--2-col" v-if="false"></div>
          </div>
        </div>
        <div
          class="itemCart itemCartFirst"
          v-for="(cart, key) in carts.filter(
            (c) => c.network_access_details == null || undefined
          )"
          :key="key"
          :class="getClassKit()"
        >
          <div class="mdl-grid">
            <div
              class="mdl-cell mdl-cell--2-col mdl-cell--1-col-tablet mdl-cell--1-col-phone"
              style="text-align: center"
            >
              <img src="@/assets/img/icon_info.png" alt="technology" />
              <br /><br />
            </div>
            <div
              class="mdl-cell mdl-cell--7-col mdl-cell--3-col-tablet mdl-cell--3-col-phone"
            >
              <span class="label">{{
                $t("checkCoverageSummary.supplyActivation")
              }}</span>
              <p class="value" v-if="!cart.category === 'XCONNECT'">
                {{ cart.title }}
              </p>
              <p class="value" v-else>
                {{ cart.related_products[0].products[0].title }}
                <small
                  v-if="cart.category === 'XCONNECT'"
                  v-show="
                    cart.cross_connect_details.is_redundant &&
                    cart.cross_connect_details.master_id
                  "
                  style="
                    text-transform: uppercase;
                    background-color: bisque;
                    padding: 5px;
                    border-radius: 5px;
                    font-size: 12px;
                  "
                  >{{ $t("crossConnects.ridondanza") }}</small
                >
              </p>
            </div>
            <div
              class="mdl-cell mdl-cell--2-col mdl-cell--12-col-tablet mdl-cell--12-col-phone contentMiddle"
            >
              <span
                :id="['item-cart-panel-' + key]"
                class="material-icons CdlIcon CdlIconReverse"
                @click="getItem(cart, cart.category === 'XCONNECT')"
                style="margin-right: 1.25rem"
                v-tooltip:bottom.tooltip="$t('cart.edit')"
                >edit</span
              >
              <span
                class="material-icons CdlIcon CdlIconReverse"
                @click="modalRemoveItem(cart.id)"
                style="margin-right: 1.25rem"
                v-tooltip:bottom.tooltip="$t('cart.deleteSolution')"
                >delete</span
              >
            </div>
            <div class="mdl-cell mdl-cell--2-col"></div>
            <div class="mdl-cell mdl-cell--8-col" v-if="false">
              <hr />
            </div>
            <div class="mdl-cell mdl-cell--2-col" v-if="false"></div>
            <div class="mdl-cell mdl-cell--2-col" v-if="false"></div>
            <div class="mdl-cell mdl-cell--8-col" v-if="false">
              <div class="mdl-grid">
                <div
                  class="mdl-cell mdl-cell--9-col mdl-cell--2-col-tablet mdl-cell--2-col-phone"
                >
                  <p class="detailOne">{{ $t("checkCoverage.priceTantum") }}</p>
                </div>
                <div
                  class="mdl-cell mdl-cell--3-col mdl-cell--2-col-tablet mdl-cell--2-col-phone"
                >
                  <p class="detailThree">
                    €{{ getPrice(cart.base_price.nrc) }}
                  </p>
                  <p class="detailTwo" v-if="false">
                    {{ $t("checkCoverage.tantum") }}
                  </p>
                </div>
                <div
                  class="mdl-cell mdl-cell--9-col mdl-cell--2-col-tablet mdl-cell--2-col-phone"
                >
                  <p class="detailOne">{{ $t("checkCoverage.priceMonth") }}</p>
                </div>
                <div
                  class="mdl-cell mdl-cell--3-col mdl-cell--2-col-tablet mdl-cell--2-col-phone"
                >
                  <p class="detailThree">
                    €{{ getPrice(cart.base_price.mrc) }}
                  </p>
                  <p class="detailTwo" v-if="false">
                    {{ $t("checkCoverage.forMonth") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="mdl-cell mdl-cell--2-col" v-if="false"></div>
          </div>
        </div>
      </div>

      <MyLoading :viewLoader="viewLoaderCart" type="" />
    </div>

    <br />

    <div class="contentTotal" v-if="carts.length > 0">
      <!-- Client PO Input Field -->
      <div class="mdl-grid" style="padding-right: 0; margin-right: -7px">
        <div class="mdl-cell mdl-cell--12-col">
          <span class="labelTwo">{{ $t("remoteHands.infoOrder") }}</span>
        </div>
        <!-- Empty cell on the left -->
        <div class="mdl-cell mdl-cell--6-col"></div>
        <!-- Input field on the right -->
        <div class="mdl-cell mdl-cell--6-col">
          <div
            class="cdl-input"
            style="display: flex; justify-content: flex-end; margin-right: 0"
          >
            <input
              type="text"
              name="client_po"
              v-model="client_po"
              :class="{ 'not-empty': client_po }"
              maxlength="72"
            />
            <label>{{ $t("checkCoverage.orderRef") }}</label>
          </div>
        </div>
      </div>
      <p class="labelTotal">{{ $t("cart.total") }}</p>
      <p class="textTotal">{{ total.toFixed(2).replace(".", ",") }}€</p>
      <p class="labelTotal">{{ $t("cart.totalMonths") }}</p>
      <p class="textTotal">{{ totalMonths.toFixed(2).replace(".", ",") }}€</p>

      <br /><br /><br />

      <div class="contentOptional">
        <div class="contentInputInline">
          <div class="cirle contentInputRadioLabel">
            <div
              class="buttonRadio buttonRadioMyCart"
              @click="ctrlConditions()"
            >
              <div id="buttonRadioMyCart"></div>
            </div>
            <span class="labelInputRadio">{{
              $t("checkCoverageSummary.conditions")
            }}</span>
          </div>
        </div>
        <p class="error" v-if="getError()" style="text-align: left">
          {{ $t("checkCoverageSummary.conditionsRequired") }}
        </p>
      </div>
    </div>

    <div v-if="carts.length > 0">
      <div
        class="cdlContentButtonSingle"
        v-if="ctrlPerms(['verifica_copertura']) && false"
      >
        <div class="cdlButtonSingle" @click="newOrder()">
          {{ $t("cart.newSupply") }}
        </div>
      </div>

      <div class="contentButtonDouble">
        <div class="cdlButton" @click="goToCart()">
          {{ $t("cart.goToCart") }}
        </div>
        <div class="cdlButton green" @click="confirmOrder()">
          {{ $t("cart.submit") }}
        </div>
      </div>
    </div>
    <div v-else>
      <h4 class="titleCartEmpty">{{ $t("cart.cartEmpty") }}</h4>
      <p class="subTitleCartEmpty" v-if="false">
        {{ $t("cart.cartEmptyText") }}
      </p>
      <div
        class="contentButtonDouble"
        v-if="ctrlPerms(['verifica_copertura']) && false"
      >
        <div class="cdlButtonSingle" style="width: 160px" @click="newOrder()">
          {{ $t("cart.newSupply") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useCartStore } from "@/stores/CartStore";

const carts = computed(() => {
  return useCartStore().cart;
});

const total = computed(() => {
  return useCartStore().total;
});

const totalMonths = computed(() => {
  return useCartStore().totalMonths;
});
</script>

<script>
import "@/assets/scss/MyCart.scss";
import { useNetworkCoverageKitStore } from "@/stores/NetworkCoverageKitStore";
import MyLoading from "@/components/MyLoading.vue";

export default {
  name: "MyCart",
  props: ["view", "getCart", "openModal"],
  components: {
    MyLoading,
  },
  data() {
    return {
      client_po: "",
      viewLoaderCart: true,
      conditions: null,
      errorConditions: false,
    };
  },
  methods: {
    closeCart() {
      this.$emit("close-cart");
    },
    getIcon(technology) {
      let rls = this.$KitStyle(technology, "icon");
      return rls;
    },
    getClassKit(technology) {
      let rls = this.$KitStyle(technology, "class");
      return rls;
    },
    // getTitle(cart) {
    //   return this.$i18n.locale == 'it' ? cart.it.title : cart.en.title;
    // },
    getAddress(address) {
      return (
        address.address.toUpperCase() +
        " " +
        address.number +
        " " +
        address.city.toUpperCase() +
        " (" +
        address.province.toUpperCase() +
        ")"
      );
    },
    getPrice(price) {
      return this.$PriceClean(price ?? 0);
    },
    newOrder() {
      this.closeCart();
      localStorage.removeItem("cdlOrderKit");
      localStorage.removeItem("cdlOrderKitAddress");
      localStorage.removeItem("cdlOrderNetworkCoverage");
      localStorage.removeItem("cdlOrderNetworkCoverageConditions");
      return this.$router.push({ name: "NetworkCoverage" + this.$i18n.locale });
    },
    goToCart() {
      this.closeCart();
      return this.$router.push({ name: "CartPage" + this.$i18n.locale });
    },
    modalRemoveItem(cart_id) {
      this.$emit("openModal", { type: "removeItemCart", cart_id: cart_id });
    },
    modalRemoveAllItems(carts) {
      this.$emit("openModal", { type: "removeAllItems", carts: carts });
    },
    confirmOrder() {
      if (!this.conditions) {
        this.errorConditions = true;
        return;
      }

      this.errorConditions = false;

      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };

      // Include client_po in the body only if it's provided
      const requestBody = this.client_po ? { client_po: this.client_po } : {};

      this.axios
        .post(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "sales/v1/order",
          requestBody,
          { headers: he }
        )
        .then(() => {
          this.$UpdateCart();
          this.$emit("openModal", { type: "confirmOrder" });
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getItem(cart, xconnect = false) {
      window.scrollTo(0, 0);

      if (xconnect) {
        this.closeCart();
        return this.$router.push({
          name: "CartItemSummary" + this.$i18n.locale,
          params: { cart_id: cart.id },
        });
      }

      if (localStorage.getItem("cdlOrderNetworkCoverage")) {
        let order = JSON.parse(localStorage.getItem("cdlOrderNetworkCoverage"));
        if (order.form.cart_id || order.form.read) {
          localStorage.removeItem("cdlOrderKit");
          localStorage.removeItem("cdlOrderKitAddress");
          localStorage.removeItem("cdlOrderNetworkCoverage");
          localStorage.removeItem("cdlOrderNetworkCoverageConditions");
        } else {
          let params = { type: "deleteOrderWizardNetworkTmp" };
          this.$emit("openModal", params);
          this.closeCart();
          return;
        }
      }

      this.viewLoaderCart = false;
      document.getElementsByTagName("body")[0].classList.add("overflow");

      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };

      const self = this;

      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "sales/v1/kit/" +
            cart.kit_id,
          { headers: he }
        )
        .then((response) => {
          const networkCoverageKitStore = useNetworkCoverageKitStore();
          networkCoverageKitStore.setKit(response.data, this.$i18n.locale);
          networkCoverageKitStore.setAddress({
            address: cart.network_access_details.address.address,
            streetNumber: cart.network_access_details.address.number,
            city: cart.network_access_details.address.city,
            province: cart.network_access_details.address.province,
          });
          let kitTmp = response.data;
          kitTmp.technology = cart.network_access_details.technology;
          kitTmp.commercial_profile =
            cart.network_access_details.commercial_profile;
          kitTmp.carrier = cart.network_access_details.carrier;
          localStorage.setItem("cdlOrderKit", JSON.stringify({ kit: kitTmp }));

          cart.kit_id = response.data.id;
          const cartStore = useCartStore();
          cartStore.getItem(cart);
          document.getElementsByTagName("body")[0].classList.remove("overflow");
          this.$router.push({ name: "CartPage" + this.$i18n.locale });
          this.closeCart();
          self.viewLoaderCart = true;
          return this.$router.push({
            name: "OrderNetworkCoverageSummary" + this.$i18n.locale,
          });
        })
        .catch(function (err) {
          console.log(err);
          self.viewLoaderCart = true;
          document.getElementsByTagName("body")[0].classList.remove("overflow");
          let params = {
            type: "KitNotFound",
            cart_id: cart.id,
            address: cart.network_access_details.address,
          };
          self.$emit("openModal", params);
          return;
        });
    },
    ctrlConditions() {
      let elm = document.getElementsByClassName("buttonRadioMyCart")[0];
      if (elm.classList.contains("active")) {
        elm.classList.remove("active");
        this.conditions = null;
      } else {
        this.conditions = 1;
        elm.classList.add("active");
        localStorage.setItem("cdlOrderNetworkCoverageConditions", 1);
      }
    },
    getError() {
      return this.errorConditions;
    },
    ctrlPerms(perms) {
      return this.$ctrlPermissions(perms);
    },
  },
};
</script>
