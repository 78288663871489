<template>
  <div id="Shipping">
    <MyBanner
      :text="$t('banner.accessDcHistoricalPage')"
      v-if="$t('banner.accessDcHistoricalPage') != ''"
    />
    <MyHeaderPage
      :title="$t('accessDc.historicalAccessDc.title')"
      :subtitle="$t('accessDc.historicalAccessDc.subTitle')"
    />
    <br /><br />

    <!-- TABLE START -->
    <div id="TicketsList">
      <div class="listTicket">
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--6-col mdl-cell mdl-cell--12-col-phone">
            <div class="filterTickets">
              <div class="contentInputFilter">
                <div class="contentIcon">
                  <span class="material-icons">search</span>
                </div>
                <div class="contentInput">
                  <input
                    type="text"
                    :placeholder="$t('actions.text_search_placeholder')"
                    v-model="searchString"
                    @keyup="searchByString"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="mdl-cell mdl-cell--2-col"
            v-if="!IsMobile"
            style="display: none"
          >
            <div class="contentInputFilter" style="margin-bottom: 0">
              <div class="multiselectFilter">
                {{ $t("accessDc.historicalAccessDc.filterInOut") }}
                <strong
                  ><span>
                    {{
                      $t(
                        "accessDc.historicalAccessDc.filter." +
                          multiSelected.typeAccess.string
                      )
                    }}</span
                  ></strong
                >
              </div>
              <div class="contentIcon cursorPointer" @click="openMultiselect()">
                <span
                  class="material-icons"
                  v-if="multiSelected.typeAccess.onOff"
                  >expand_less</span
                >
                <span
                  class="material-icons closeMultiselectFilter"
                  v-if="!multiSelected.typeAccess.onOff"
                  >expand_more</span
                >
              </div>
            </div>
            <div class="listMultiselect" v-if="multiSelected.typeAccess.onOff">
              <div>
                <input
                  type="checkbox"
                  class="typeAccessAll checkTypeAccess"
                  value="all"
                  @click="searchByParams('all')"
                />
                {{ $t("checkCoverage.filter.all") }}
              </div>
              <div>
                <input
                  type="checkbox"
                  class="checkTypeAccess"
                  value="in"
                  @click="searchByParams('in')"
                />
                {{ $t("accessDc.historicalAccessDc.filter.in") }}
              </div>
              <div>
                <input
                  type="checkbox"
                  class="checkTypeAccess"
                  value="out"
                  @click="searchByParams('out')"
                />
                {{ $t("accessDc.historicalAccessDc.filter.out") }}
              </div>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--3-col mdl-cell mdl-cell--12-col-phone">
            <div class="cdl-input" style="margin-top: 0">
              <input
                type="date"
                name="dateFrom"
                class="not-empty"
                v-model="dateFrom"
                style="background-color: white"
                @change="getAccessDcList()"
              />
              <label>{{ $t("reports.filter.dateFrom") }}</label>
              <span
                class="error"
                v-if="this.v$.dateFrom.$invalid && this.v$.dateFrom.$dirty"
              >
                {{ $t("reports.filter.dateFrom") }}
                {{ $t("reports.filter.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--3-col mdl-cell mdl-cell--12-col-phone">
            <div class="cdl-input" style="margin-top: 0">
              <input
                type="date"
                name="dateTo"
                class="not-empty"
                v-model="dateTo"
                style="background-color: white"
                @change="getAccessDcList()"
              />
              <label>{{ $t("reports.filter.dateTo") }}</label>
              <span
                class="error"
                v-if="this.v$.dateTo.$invalid && this.v$.dateTo.$dirty"
              >
                {{ $t("reports.filter.dateTo") }}
                {{ $t("reports.filter.isRequired") }}
              </span>
            </div>
          </div>
        </div>

        <div v-if="!IsMobile">
          <table v-if="columns.length > 0">
            <thead>
              <tr>
                <th v-for="(header, keyHead) in this.columns" :key="keyHead">
                  {{ $t("accessDc.historicalAccessDc.col." + header) }}
                </th>
              </tr>
            </thead>
            <tbody v-if="this.viewLoader">
              <tr
                v-for="(item, keyItem) in this.items"
                :key="keyItem"
                :class="{ odd: keyItem % 2 }"
              >
                <td
                  v-for="(index, keyIndex) in this.columns.length"
                  :key="keyIndex"
                  :class="{ textCenter: this.columns[keyIndex] == 'panel' }"
                >
                  <span
                    v-if="this.columns[keyIndex] == 'panel'"
                    :class="{
                      in: item[this.columns[keyIndex]] == 'in',
                      out: item[this.columns[keyIndex]] == 'out',
                    }"
                    >{{
                      $t(
                        "accessDc.historicalAccessDc.filter." +
                          item[this.columns[keyIndex]]
                      )
                    }}</span
                  >
                  <span
                    v-else-if="
                      this.columns[keyIndex] == 'time' ||
                      this.columns[keyIndex] == 'from' ||
                      this.columns[keyIndex] == 'to'
                    "
                    >{{ cleanDate(item[this.columns[keyIndex]], "time") }}</span
                  >
                  <span v-else-if="this.columns[keyIndex] == 'date'">{{
                    cleanDate(item[this.columns[keyIndex]])
                  }}</span>
                  <span v-else>{{ item[this.columns[keyIndex]] }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="IsMobile && colMobile.length > 0">
          <table>
            <thead>
              <tr>
                <th
                  style="text-align: center"
                  v-for="(header, keyHead) in colMobile"
                  :key="keyHead"
                >
                  <span>{{
                    $t("accessDc.historicalAccessDc.col." + header)
                  }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <TicketsMobileRow
                class="marco"
                v-for="(item, keyItem) in this.items"
                :key="keyItem"
              >
                <template #first-col>{{ item.surname }}</template>
                <template #second-col>{{ item.name }}</template>
                <template #url-label>
                  <p style="text-align: center">{{ cleanDate(item.date) }}</p>
                  <p style="text-align: center">
                    {{ cleanDate(item.from, "time") }} -
                    {{ cleanDate(item.to, "time") }}
                  </p>
                </template>
              </TicketsMobileRow>
            </tbody>
          </table>
        </div>

        <div class="noTickets" v-if="items.length == 0 && viewLoader">
          <br />{{ $t("accessDc.historicalAccessDc.noUsers") }}
        </div>
        <div>
          <br /><br />
          <MyLoading :viewLoader="this.viewLoader" type="" />
          <MyError :viewError="viewError" :message="$t('tickets.error')" />
        </div>
        <br /><br />
        <Paginator
          v-show="total_page > 1"
          :total-page="total_page"
          :current-page="current_page"
          @change-page="changePage"
          @prev-page="beforePage"
          @next-page="nextPage"
        />
        <br /><br />
      </div>
    </div>

    <!-- TABLE END -->
  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import "@/assets/scss/Shipping.scss";
import moment from "moment";

import MyLoading from "@/components/MyLoading.vue";
import MyError from "@/components/MyError.vue";
import Paginator from "@/components/Paginator.vue";
import TicketsMobileRow from "@/components/Tables/TicketsMobileRow.vue";
import "@/assets/scss/TicketsList.scss";

import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "AccessRequestHistorical",
  components: {
    MyBanner,
    MyHeaderPage,
    MyLoading,
    MyError,
    Paginator,
    TicketsMobileRow,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      dateFrom: { required },
      dateTo: { required },
    };
  },
  data() {
    return {
      columns: ["surname", "name", "date", "from", "to"],
      items: [],
      searchString: "",
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
        "Content-Type": "multipart/form-data",
      },
      page_size: 10,
      total_page: 1,
      current_page: 1,
      viewLoader: true,
      viewError: false,
      messageError: "",
      IsMobile: this.$IsMobile(),
      colMobile: ["surname", "name"],
      allItems: [],
      indexFirst: 0,
      dateFrom: moment().subtract(6, "months").format("DD-MM-YYYY"),
      dateTo: moment().format("DD-MM-YYYY"),
      typeAccess: "",
      multiSelected: {
        typeAccess: {
          onOff: false,
          string: "all",
          values: [],
        },
      },
    };
  },
  methods: {
    searchByString() {
      if (this.searchString.length <= 3 || this.searchString.length == 0) {
        this.total_page = Math.ceil(this.allItems.length / this.page_size);
        this.current_page = 1;
        this.indexFirst = 0;
        this.items = this.allItems.slice(
          this.indexFirst,
          this.current_page * this.page_size
        );
      } else {
        this.total_page = 0;

        this.items = [];
        this.allItems.forEach((elm) => {
          let surname = elm.surname
            ? elm.surname
                .toLowerCase()
                .includes(this.searchString.toLowerCase())
            : false;
          let name = elm.name
            ? elm.name.toLowerCase().includes(this.searchString.toLowerCase())
            : false;
          let date = elm.date
            ? elm.date.toLowerCase().includes(this.searchString.toLowerCase())
            : false;
          let from = elm.from
            ? elm.from.toLowerCase().includes(this.searchString.toLowerCase())
            : false;
          let to = elm.to
            ? elm.to.toLowerCase().includes(this.searchString.toLowerCase())
            : false;
          if (surname || name || date || from || to) {
            this.items.push(elm);
          }
        });
      }
    },
    async getAccessDcList() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }

      this.viewLoader = false;

      this.total_page = 0;

      const self = this;

      this.viewError = false;

      this.items = [];

      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "front/v1/dc-accesses?from=" +
            this.dateFrom +
            "&to=" +
            this.dateTo,
          { headers: this.he }
        )
        .then((response) => {
          this.viewLoader = true;

          if (response.status == 200 && response.data.length > 0) {
            this.allItems = response.data;
            this.indexFirst = 0;
            this.current_page = 1;
            this.total_page = Math.ceil(response.data.length / this.page_size);
            this.items = this.allItems.slice(
              this.indexFirst,
              this.current_page * this.page_size
            );
            this.indexFirst = 11;
          }
        })
        .catch(function (err) {
          console.log(err);
          if (err.request.status == 404) {
            self.viewLoader = true;
            return;
          }
          self.viewLoader = true;
          self.viewError = true; //err.message; //"Nessuna copertura";
          self.messageError = err.message;
          setTimeout(() => {
            self.viewError = false;
          }, 5000);
        });
    },
    changePage(page) {
      this.current_page = parseInt(page);
      this.updateElms();
    },
    nextPage() {
      if (this.current_page == this.total_page) {
        return;
      }
      this.current_page = parseInt(this.current_page) + 1;
      this.updateElms();
    },
    beforePage() {
      if (this.current_page == 1) {
        return;
      }
      this.current_page = parseInt(this.current_page) - 1;
      this.updateElms();
    },
    updateElms() {
      let lastTen = this.current_page * this.page_size;
      this.indexFirst = lastTen - (this.page_size - 1);
      this.items = this.allItems.slice(
        this.indexFirst,
        this.current_page * this.page_size
      );
    },
    cleanDate(date, type = null) {
      if (type == "time") {
        return moment(date).format("HH:mm:ss");
      }
      return moment(date).format("DD-MM-YYYY");
    },
    searchByParams(type) {
      let checkBox = document.getElementsByClassName("checkTypeAccess");
      Object.entries(checkBox).forEach((elm) => {
        elm[1].checked = false;
        if (elm[1].value == type) {
          elm[1].checked = true;
          this.multiSelected.typeAccess.values[0] = type;
          this.multiSelected.typeAccess.string = type;
        }
      });

      if (type == "all") {
        this.getAccessDcList();
        return;
      }

      this.items = [];
      this.allItems.forEach((elm) => {
        if (elm.panel == type) {
          this.items.push(elm);
        }
      });
    },
    openMultiselect() {
      let ctrl = false;
      let selected = [];
      let pointer = this.multiSelected.typeAccess;

      (function (x) {
        x.onOff = !x.onOff;
        ctrl = x.onOff;
        selected = x.values;
      })(pointer);

      if (ctrl && selected.length > 0) {
        let checkBox = document.getElementsByClassName("typeAccess");
        setTimeout(() => {
          for (let i = 0; i < checkBox.length; i++) {
            checkBox[i].checked = false;
            selected.forEach((elm) => {
              if (checkBox[i].value == elm) {
                checkBox[i].checked = true;
              }
            });
          }
        }, 100);
      } else if (ctrl && selected.length == 0) {
        let classAll = "typeAccessAll";
        setTimeout(() => {
          console.log(document.getElementsByClassName(classAll)[0], classAll);
          document.getElementsByClassName(classAll)[0].checked = true;
        }, 100);
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    this.getAccessDcList();
  },
};
</script>
