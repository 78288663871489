<template>
  <div id="Users">
    <MyBanner
      :text="$t('banner.accessDcPage')"
      v-if="$t('banner.accessDcPage') != ''"
    />
    <MyHeaderPage
      :title="$t('reports.current.title')"
      :subtitle="$t('reports.current.subTitle')"
    />
    <br /><br />
    <div id="TicketsList">
      <div class="listTicket">
        <!-- FILTRO -->
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--6-col">
            <div class="filterTickets">
              <div class="contentInputFilter">
                <div class="contentIcon">
                  <span class="material-icons">search</span>
                </div>
                <div class="contentInput">
                  <input
                    type="text"
                    :placeholder="$t('actions.text_search_placeholder')"
                    v-model="searchString"
                    @keyup="searchByString"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--3-col">
            <div class="cdl-input" style="margin-top: 0">
              <input
                type="date"
                name="dateFrom"
                class="not-empty"
                v-model="dateFrom"
                style="background-color: white"
                @change="getList()"
              />
              <label>{{ $t("reports.filter.dateFrom") }}</label>
              <span
                class="error"
                v-if="this.v$.dateFrom.$invalid && this.v$.dateFrom.$dirty"
              >
                {{ $t("reports.filter.dateFrom") }}
                {{ $t("reports.filter.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--3-col">
            <div class="cdl-input" style="margin-top: 0">
              <input
                type="date"
                name="dateTo"
                class="not-empty"
                v-model="dateTo"
                style="background-color: white"
                @change="getList()"
              />
              <label>{{ $t("reports.filter.dateTo") }}</label>
              <span
                class="error"
                v-if="this.v$.dateTo.$invalid && this.v$.dateTo.$dirty"
              >
                {{ $t("reports.filter.dateTo") }}
                {{ $t("reports.filter.isRequired") }}
              </span>
            </div>
          </div>
        </div>
        <!-- FINE FILTRO -->

        <div class="mdl-grid currentLegend" v-if="itemFilter.length > 0">
          <div class="mdl-cell mdl-cell--12-col">
            <h3 class="titleH3">{{ $t("reports.current.titleLegend") }}</h3>
            <br />
            <div v-html="$t('reports.current.textLegend')"></div>
          </div>
        </div>

        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <br />
            <table>
              <thead>
                <tr>
                  <th>
                    {{ $t("reports.current.col.colOne") }}
                  </th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.current.col.colTwo") }}
                  </th>
                  <th>{{ $t("reports.current.col.colThree") }}</th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.current.col.colFour") }}
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="
                  !this.$IsMobile() && this.viewLoader && itemFilter.length > 0
                "
              >
                <tr
                  v-for="(item, keyItem) in this.itemFilter"
                  :key="keyItem"
                  :class="{ odd: keyItem % 2 }"
                >
                  <td>{{ item.data }}</td>
                  <td>
                    {{ $t("reports.current.month." + item.data.split("-")[1]) }}
                    {{ item.data.split("-")[0] }}
                  </td>
                  <td>{{ item.tipo }}</td>
                  <td>
                    <div class="cdlButton" @click="download(item.url)">
                      {{ $t("reports.current.download") }}
                    </div>
                  </td>
                </tr>
              </tbody>

              <tbody
                v-else-if="
                  this.$IsMobile() && this.viewLoader && itemFilter.length > 0
                "
              >
                <MobileRow
                  v-for="(item, keyItem) in this.itemFilter"
                  :key="keyItem"
                  :cols="['data', 'tipo']"
                  :item="item"
                >
                  <template #url-label>
                    <div class="cdlButton" @click="download(item.url)">
                      {{ $t("reports.current.download") }}
                    </div>
                  </template></MobileRow
                >
              </tbody>

              <tbody v-if="items.length == 0 && viewLoader">
                <tr>
                  <td colspan="4">
                    <span class="noTickets">{{ $t("reports.noResult") }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <MyLoading :viewLoader="this.viewLoader" type="" />
            </div>
          </div>
        </div>

        <div class="mdl-grid currentLegend" v-if="itemFilter.length > 0">
          <div class="mdl-cell mdl-cell--12-col">
            <div class="titleH3">
              {{ $t("reports.current.titleLegendExel") }}
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <p class="label">{{ $t("reports.current.typeFile") }}: Summary</p>
            <ul>
              <li>
                <strong>datacenter:</strong>
                {{ $t("reports.current.ConsumiEnergiaDatacenter") }}
              </li>
              <li>
                <strong>island:</strong>
                {{ $t("reports.current.ConsumiEnergiaIsland") }}
              </li>
              <li>
                <strong>rack_id:</strong>
                {{ $t("reports.current.ConsumiEnergiaRackId") }}
              </li>
              <li>
                <strong>rack_name:</strong>
                {{ $t("reports.current.ConsumiEnergiaRackName") }}
              </li>
              <li>
                <strong>ampere:</strong>
                {{ $t("reports.current.ConsumiEnergiaAmpere") }}
              </li>
            </ul>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <p class="label">{{ $t("reports.current.typeFile") }}: Details</p>
            <ul>
              <li>
                <strong>datacenter:</strong>
                {{ $t("reports.current.ConsumiEnergiaDatacenter") }}
              </li>
              <li>
                <strong>island:</strong>
                {{ $t("reports.current.ConsumiEnergiaIsland") }}
              </li>
              <li>
                <strong>rack_id:</strong>
                {{ $t("reports.current.ConsumiEnergiaRackId") }}
              </li>
              <li>
                <strong>rack_name:</strong>
                {{ $t("reports.current.ConsumiEnergiaRackName") }}
              </li>
              <li>
                <strong>rack_socket_id:</strong>
                {{ $t("reports.current.ConsumiEnergiaSocketId") }}
              </li>
              <li>
                <strong>magnetotermico:</strong>
                {{ $t("reports.current.ConsumiEnergiaMagnetotermico") }}
              </li>
              <li>
                <strong>date:</strong>
                {{ $t("reports.current.ConsumiEnergiaDate") }}
              </li>
              <li>
                <strong>ampere:</strong>
                {{ $t("reports.current.ConsumiEnergiaAmpere") }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import MyLoading from "@/components/MyLoading.vue";
import MobileRow from "@/components/Tables/MobileRow.vue";
import "@/assets/scss/TicketsList.scss";
import moment from "moment";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "CCurrent",
  components: {
    MyBanner,
    MyHeaderPage,
    MyLoading,
    MobileRow,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      dateFrom: { required },
      dateTo: { required },
    };
  },
  data() {
    return {
      viewLoader: false,
      items: [],
      itemFilter: [],
      // items: [
      //   {
      //       "id": 2947,
      //       "customer_id": 328,
      //       "data": "2023-08-31",
      //       "tipo": "Colo: ampere details",
      //       "url": "https://allegati.s3.cloudlan.it/50028a9c-9566-4377-a29f-ecbb0ba39b1b-202308-details.csv",
      //       "uploaded": 1
      //   },
      //   {
      //       "id": 2947,
      //       "customer_id": 328,
      //       "data": "2024-08-31",
      //       "tipo": "Colo: ciccio details",
      //       "url": "https://allegati.s3.cloudlan.it/50028a9c-9566-4377-a29f-ecbb0ba39b1b-202308-details.csv",
      //       "uploaded": 1
      //   }
      // ],
      // itemFilter: [
      //   {
      //       "id": 2947,
      //       "customer_id": 328,
      //       "data": "2023-08-31",
      //       "tipo": "Colo: ampere details",
      //       "url": "https://allegati.s3.cloudlan.it/50028a9c-9566-4377-a29f-ecbb0ba39b1b-202308-details.csv",
      //       "uploaded": 1
      //   },
      //   {
      //       "id": 2947,
      //       "customer_id": 328,
      //       "data": "2024-08-31",
      //       "tipo": "Colo: ciccio details",
      //       "url": "https://allegati.s3.cloudlan.it/50028a9c-9566-4377-a29f-ecbb0ba39b1b-202308-details.csv",
      //       "uploaded": 1
      //   }
      // ],
      dateFrom: moment().subtract(6, "months").format("DD-MM-YYYY"),
      dateTo: moment().format("DD-MM-YYYY"),
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      searchString: "",
    };
  },
  methods: {
    async getList() {
      const result = await this.v$.$validate();
      if (result) {
        const self = this;
        this.axios
          .get(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
              "front/v1/report-power/?from=" +
              this.dateFrom +
              "&to=" +
              this.dateTo,
            { headers: this.he }
          )
          .then((rls) => {
            this.items = rls.data;
            this.itemFilter = this.items;
            this.viewLoader = true;
          })
          .catch(function (err) {
            self.viewLoader = true;
            console.log(err);
          });
      }
    },
    searchByString() {
      this.itemFilter = [];
      if (this.searchString.length >= 3) {
        let elm = this.searchString.toLowerCase();
        this.items.forEach((item) => {
          let data = item.data ? item.data.toLowerCase().includes(elm) : false;
          let tipo = item.tipo ? item.tipo.toLowerCase().includes(elm) : false;
          if (data || tipo) {
            this.itemFilter.push(item);
          }
        });
      } else {
        this.itemFilter = this.items;
      }
    },
    download(url) {
      window.open(url, "_blank").focus();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
