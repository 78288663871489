<script setup>
import { defineProps } from "vue";
import moment from "moment"; // Import moment.js

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  titles: {
    type: Array,
    required: true,
  },
  translationPath: {
    type: String,
    required: true,
  },
});

// Method to format date values
const getDateTime = (myTime) => {
  return moment(myTime).format("DD-MM-YYYY HH:mm");
};

// Function to check if a value is a date
const isDate = (value) => {
  // Simple check to determine if the value is a valid date
  return moment(value, moment.ISO_8601, true).isValid();
};
</script>

<template>
  <div class="mobile-card">
    <!-- Iterate over the titles and use the translated keys -->
    <div
      v-for="(key, index) in props.titles"
      :key="index"
      class="mobile-card__item"
    >
      <strong class="mobile-card__title">
        {{ $t(`${props.translationPath}.${key}`) }}:
      </strong>
      <span class="mobile-card__value">
        <!-- Check if the value is a date; if so, format it -->
        {{
          isDate(props.item[key])
            ? getDateTime(props.item[key])
            : props.item[key] || "N/A"
        }}
      </span>
    </div>

    <!-- Slot for the button -->
    <div class="mobile-card__button">
      <slot name="url-label"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.mobile-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    font-family: MontserratSemiBold;
    color: #6585a7;
    font-size: 1rem;
    text-align: left;
    flex-wrap: wrap;
    word-wrap: break-word;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-weight: 600;
    color: #333;
    flex: 0 1 30%;
    margin-right: 10px;
  }

  &__value {
    color: #555;
    text-align: left;
    flex: 1;
    max-width: 65%;
    word-wrap: break-word;
    white-space: normal;
  }

  &__button {
    margin-top: 10px;
    text-align: right;

    .cdlButton {
      background-color: #00a4e6;
      color: white;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
      display: inline-block;
      transition: background-color 0.3s;

      &:hover {
        background-color: #008bbd;
      }
    }
  }
}
</style>
