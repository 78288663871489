<template>
  <div id="Users">
    <MyBanner
      :text="$t('banner.accessDcPage')"
      v-if="$t('banner.accessDcPage') != ''"
    />
    <MyHeaderPage
      :title="$t('reports.voip.title')"
      :subtitle="$t('reports.voip.subTitle')"
    />
    <br /><br />
    <div id="TicketsList">
      <div class="listTicket">
        <!-- FILTRO -->
        <div class="mdl-grid">
          <div
            class="mdl-cell"
            :class="[
              Object.entries(bills).length > 0
                ? 'mdl-cell--3-col'
                : 'mdl-cell--6-col',
            ]"
          >
            <div class="filterTickets" style="margin-top: 20px">
              <div class="contentInputFilter">
                <div class="contentIcon">
                  <span class="material-icons">search</span>
                </div>
                <div class="contentInput">
                  <input
                    type="text"
                    :placeholder="$t('actions.text_search_placeholder')"
                    v-model="searchString"
                    @keyup="searchByString"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--3-col">
            <div class="cdl-input" style="margin-top: 20px">
              <input
                type="date"
                name="dateFrom"
                class="not-empty"
                v-model="dateFrom"
                style="background-color: white"
                @change="getList()"
              />
              <label>{{ $t("reports.filter.dateFrom") }}</label>
              <span
                class="error"
                v-if="this.v$.dateFrom.$invalid && this.v$.dateFrom.$dirty"
              >
                {{ $t("reports.filter.dateFrom") }}
                {{ $t("reports.filter.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--3-col">
            <div class="cdl-input" style="margin-top: 20px">
              <input
                type="date"
                name="dateTo"
                class="not-empty"
                v-model="dateTo"
                style="background-color: white"
                @change="getList()"
              />
              <label>{{ $t("reports.filter.dateTo") }}</label>
              <span
                class="error"
                v-if="this.v$.dateTo.$invalid && this.v$.dateTo.$dirty"
              >
                {{ $t("reports.filter.dateTo") }}
                {{ $t("reports.filter.isRequired") }}
              </span>
            </div>
          </div>

          <div
            class="mdl-cell mdl-cell--3-col"
            v-if="Object.entries(bills).length > 0"
          >
            <div class="contentSelect">
              <span class="labelOne">{{ $t("reports.voip.bill") }}</span>
              <select
                name="datacenter"
                id="selectedUser"
                class="align"
                v-model="bill_id"
                @change="getList()"
                style="margin: 0; background-color: white"
              >
                <option :value="k" v-for="(bill, k) in bills" :key="k">
                  {{ bill }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!-- FINE FILTRO -->
        <div class="mdl-grid">
          <div
            class="mdl-cell mdl-cell--2-col mdl-cell--10-offset"
            v-if="itemFilter.length > 0"
          >
            <download-csv
              :data="voipCsv"
              :name="'Report VoIP ' + dateFrom + ' - ' + dateTo"
              :delimiter="';'"
              :labels="{
                from_call: $t('reports.voip.col.colOne'),
                to_call: $t('reports.voip.col.colTwo'),
                time_call: $t('reports.voip.col.colThree'),
                price_call: $t('reports.voip.col.colFour'),
                price_inc: $t('reports.voip.col.colFive'),
                data_call: $t('reports.voip.col.colSix'),
                destination: $t('reports.voip.col.colSeven'),
              }"
            >
              <button class="cdlButton">
                <img src="@/assets/img/download.png" alt="icon" />
                {{ $t("users.downLoadCSV") }}
              </button>
            </download-csv>
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <br />
            <table>
              <thead>
                <tr>
                  <th>{{ $t("reports.voip.col.colOne") }}</th>
                  <th>{{ $t("reports.voip.col.colTwo") }}</th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.voip.col.colThree") }}
                  </th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.voip.col.colFour") }}
                  </th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.voip.col.colFive") }}
                  </th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.voip.col.colSix") }}
                  </th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.voip.col.colSeven") }}
                  </th>
                  <th v-if="false"></th>
                </tr>
              </thead>
              <tbody
                v-if="
                  !this.$IsMobile() && this.viewLoader && itemFilter.length > 0
                "
              >
                <tr
                  v-for="(item, keyItem) in this.itemFilter"
                  :key="keyItem"
                  :class="{ odd: keyItem % 2 }"
                >
                  <td>{{ item.from_call }}</td>
                  <td>{{ item.to_call }}</td>
                  <td>{{ item.time_call }}</td>
                  <td>{{ item.price_call }}</td>
                  <td>{{ item.price_inc }}</td>
                  <td>{{ getDate(item.data_call) }}</td>
                  <td>{{ item.destination }}</td>
                </tr>
              </tbody>
              <tbody
                v-else-if="
                  this.$IsMobile() && this.viewLoader && itemFilter.length > 0
                "
              >
                <MobileRow
                  v-for="(item, keyItem) in this.itemFilter"
                  :key="keyItem"
                  :cols="['from_call', 'to_call']"
                  :item="item"
                ></MobileRow>
              </tbody>

              <tbody v-if="items.length == 0 && viewLoader">
                <tr>
                  <td colspan="4">
                    <span class="noTickets">{{ $t("reports.noResult") }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <MyLoading :viewLoader="this.viewLoader" type="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import MyLoading from "@/components/MyLoading.vue";
import MobileRow from "@/components/Tables/MobileRow.vue";
import "@/assets/scss/TicketsList.scss";
import moment from "moment";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import downloadCsv from "vue-json-csv";

export default {
  name: "CVoip",
  components: {
    MyBanner,
    MyHeaderPage,
    MyLoading,
    downloadCsv,
    MobileRow,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      dateFrom: { required },
      dateTo: { required },
    };
  },
  data() {
    return {
      viewLoader: false,
      items: [],
      itemFilter: [],
      bills: [],
      voipCsv: [],
      mdlCell3Col: "mdl-cell--3-col",
      mdlCell6Col: "mdl-cell--6-col",
      // items: [
      //   {
      //       "id": 0,
      //       "data_call": 1704301647,
      //       "from_call": "04211896061",
      //       "to_call": "39199199XXX",
      //       "time_call": "00:06:37",
      //       "price_call": "3.3083",
      //       "price_inc": "0.0",
      //       "destination": "Italy Telecom Italia"
      //   },
      //   {
      //       "id": 0,
      //       "data_call": 1704301647,
      //       "from_call": "04211896061",
      //       "to_call": "444499199XXX",
      //       "time_call": "00:0:37",
      //       "price_call": "4.3083",
      //       "price_inc": "2.0",
      //       "destination": "Italy Fastweb Italia"
      //   }
      // ],
      // itemFilter: [
      //   {
      //       "id": 0,
      //       "data_call": 1704301647,
      //       "from_call": "04211896061",
      //       "to_call": "39199199XXX",
      //       "time_call": "00:06:37",
      //       "price_call": "3.3083",
      //       "price_inc": "0.0",
      //       "destination": "Italy Telecom Italia"
      //   },
      //   {
      //       "id": 0,
      //       "data_call": 1704301647,
      //       "from_call": "04211896061",
      //       "to_call": "444499199XXX",
      //       "time_call": "00:0:37",
      //       "price_call": "4.3083",
      //       "price_inc": "2.0",
      //       "destination": "Italy Fastweb Italia"
      //   }
      // ],
      dateFrom: moment().subtract(1, "days").format("DD-MM-YYYY"),
      dateTo: moment().format("DD-MM-YYYY"),
      bill_id: null,
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      searchString: "",
    };
  },
  methods: {
    async getList() {
      const result = await this.v$.$validate();
      if (result) {
        const self = this;

        this.viewLoader = false;

        let par = "";
        if (this.bill_id) {
          par = "&bill_id=" + this.bill_id;
        }

        this.items = [];
        this.itemFilter = [];

        this.axios
          .get(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
              "front/v1/report-voip/?from=" +
              this.dateFrom +
              "&to=" +
              this.dateTo +
              par,
            { headers: this.he }
          )
          .then((rls) => {
            console.log(rls.data.items);
            if (rls.data.items.length > 0) {
              this.items = rls.data.items;
              this.itemFilter = this.items;
              console.log(this.itemFilter);
            } else {
              this.bills = rls.data.bills;
            }

            this.viewLoader = true;
            this.buildCsv();
          })
          .catch(function (err) {
            setTimeout(() => {
              self.viewLoader = true;
            }, 15000);
            console.log(err);
          });
      }
    },
    searchByString() {
      this.itemFilter = [];
      if (this.searchString.length >= 3) {
        let elm = this.searchString.toLowerCase();
        this.items.forEach((item) => {
          let from_call = item.from_call
            ? item.from_call.toLowerCase().includes(elm)
            : false;
          let to_call = item.to_call
            ? item.to_call.toLowerCase().includes(elm)
            : false;
          let time_call = item.time_call
            ? item.time_call.toLowerCase().includes(elm)
            : false;
          let price_call = item.price_call
            ? item.price_call.toLowerCase().includes(elm)
            : false;
          let price_inc = item.price_inc
            ? item.price_inc.toLowerCase().includes(elm)
            : false;
          let data_call = item.data_call
            ? this.getDate(item.data_call).toLowerCase().includes(elm)
            : false;
          let destination = item.destination
            ? item.destination.toLowerCase().includes(elm)
            : false;
          if (
            from_call ||
            to_call ||
            time_call ||
            price_call ||
            price_inc ||
            data_call ||
            destination
          ) {
            this.itemFilter.push(item);
          }
        });
      } else {
        this.itemFilter = this.items;
      }
      this.buildCsv();
    },
    getDate(myTime) {
      return moment.unix(myTime).format("DD-MM-YYYY HH:mm:ss");
    },
    buildCsv() {
      // Object.assign(this.voipCsv, this.itemFilter);

      this.voipCsv = this.itemFilter.map((elem) => ({
        from_call: "'" + elem.from_call + "'",
        to_call: elem.to_call,
        time_call: elem.time_call,
        price_call: elem.price_call,
        price_inc: elem.price_inc,
        data_call: this.getDate(elem.data_call),
        destination: elem.destination,
      }));
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
