<template>
  <div id="Users">
    <MyBanner
      :text="$t('banner.accessDcPage')"
      v-if="$t('banner.accessDcPage') != ''"
    />
    <MyHeaderPage
      :title="$t('reports.fax.title')"
      :subtitle="$t('reports.fax.subTitle')"
    />
    <br /><br />
    <div id="TicketsList">
      <div class="listTicket">
        <!-- FILTRO -->
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--6-col">
            <div class="filterTickets">
              <div class="contentInputFilter">
                <div class="contentIcon">
                  <span class="material-icons">search</span>
                </div>
                <div class="contentInput">
                  <input
                    type="text"
                    :placeholder="$t('actions.text_search_placeholder')"
                    v-model="searchString"
                    @keyup="searchByString"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--3-col">
            <div class="cdl-input" style="margin-top: 0">
              <input
                type="date"
                name="dateFrom"
                class="not-empty"
                v-model="dateFrom"
                style="background-color: white"
                @change="getList()"
              />
              <label>{{ $t("reports.filter.dateFrom") }}</label>
              <span
                class="error"
                v-if="this.v$.dateFrom.$invalid && this.v$.dateFrom.$dirty"
              >
                {{ $t("reports.filter.dateFrom") }}
                {{ $t("reports.filter.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--3-col">
            <div class="cdl-input" style="margin-top: 0">
              <input
                type="date"
                name="dateTo"
                class="not-empty"
                v-model="dateTo"
                style="background-color: white"
                @change="getList()"
              />
              <label>{{ $t("reports.filter.dateTo") }}</label>
              <span
                class="error"
                v-if="this.v$.dateTo.$invalid && this.v$.dateTo.$dirty"
              >
                {{ $t("reports.filter.dateTo") }}
                {{ $t("reports.filter.isRequired") }}
              </span>
            </div>
          </div>
        </div>
        <!-- FINE FILTRO -->
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <br />
            <table>
              <thead>
                <tr>
                  <th>{{ $t("reports.fax.col.colOne") }}</th>
                  <th>{{ $t("reports.fax.col.colTwo") }}</th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.fax.col.colThree") }}
                  </th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.fax.col.colFour") }}
                  </th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.fax.col.colFive") }}
                  </th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.fax.col.colSix") }}
                  </th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.fax.col.colSeven") }}
                  </th>
                  <th v-if="false"></th>
                </tr>
              </thead>
              <tbody
                v-if="
                  !this.$IsMobile() && this.viewLoader && itemFilter.length > 0
                "
              >
                <tr
                  v-for="(item, keyItem) in this.itemFilter"
                  :key="keyItem"
                  :class="{ odd: keyItem % 2 }"
                >
                  <td>{{ getDate(item.data_invio) }}</td>
                  <td>{{ item.from_send }}</td>
                  <td>{{ item.to_send }}</td>
                  <td>{{ item.page_send }}</td>
                  <td>{{ item.durata }}</td>
                  <td>{{ item.costo_pag }}</td>
                  <td>{{ item.importo }}</td>
                </tr>
              </tbody>
              <tbody
                v-else-if="
                  this.$IsMobile() && this.viewLoader && itemFilter.length > 0
                "
              >
                <MobileRow
                  v-for="(item, keyItem) in this.itemFilter"
                  :key="keyItem"
                  :cols="['from_send', 'to_send']"
                  :item="item"
                >
                  <template #url-label>
                    <div class="cdlButton" @click="download(item.url)">
                      {{ $t("reports.current.download") }}
                    </div>
                  </template></MobileRow
                >
              </tbody>
              <tbody v-if="items.length == 0 && viewLoader">
                <tr>
                  <td colspan="4">
                    <span class="noTickets">{{ $t("reports.noResult") }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <MyLoading :viewLoader="this.viewLoader" type="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import MyLoading from "@/components/MyLoading.vue";
import "@/assets/scss/TicketsList.scss";
import moment from "moment";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import MobileRow from "@/components/Tables/MobileRow.vue";

export default {
  name: "CFax",
  components: {
    MyBanner,
    MyHeaderPage,
    MyLoading,
    MobileRow,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      dateFrom: { required },
      dateTo: { required },
    };
  },
  data() {
    return {
      viewLoader: false,
      items: [],
      itemFilter: [],
      // items: [
      //   {
      //       "id": 0,
      //       "data_invio": 1703843647,
      //       "from_send": "segreteria@gruppomilleuno.com",
      //       "to_send": "0650748XXX",
      //       "page_send": 2,
      //       "durata": "0:01:05",
      //       "costo_pag": "0.070",
      //       "importo": "0.140"
      //   },
      //   {
      //       "id": 0,
      //       "data_invio": 1703843647,
      //       "from_send": "segreteria@gruppomilleunodue.com",
      //       "to_send": "0750748XXX",
      //       "page_send": 2,
      //       "durata": "0:02:05",
      //       "costo_pag": "0.080",
      //       "importo": "0.170"
      //   }
      // ],
      // itemFilter: [
      //   {
      //       "id": 0,
      //       "data_invio": 1703843647,
      //       "from_send": "segreteria@gruppomilleuno.com",
      //       "to_send": "0650748XXX",
      //       "page_send": 2,
      //       "durata": "0:01:05",
      //       "costo_pag": "0.070",
      //       "importo": "0.140"
      //   },
      //   {
      //       "id": 0,
      //       "data_invio": 1703843647,
      //       "from_send": "segreteria@gruppomilleunodue.com",
      //       "to_send": "0750748XXX",
      //       "page_send": 2,
      //       "durata": "0:02:05",
      //       "costo_pag": "0.080",
      //       "importo": "0.170"
      //   }
      // ],
      dateFrom: moment().subtract(6, "months").format("DD-MM-YYYY"),
      dateTo: moment().format("DD-MM-YYYY"),
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      searchString: "",
    };
  },
  methods: {
    async getList() {
      const result = await this.v$.$validate();
      if (result) {
        const self = this;
        this.axios
          .get(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
              "front/v1/report-fax/?from=" +
              this.dateFrom +
              "&to=" +
              this.dateTo,
            { headers: this.he }
          )
          .then((rls) => {
            this.items = rls.data;
            this.itemFilter = this.items;
            this.viewLoader = true;
          })
          .catch(function (err) {
            self.viewLoader = true;
            console.log(err);
          });
      }
    },
    searchByString() {
      this.itemFilter = [];
      if (this.searchString.length >= 3) {
        let elm = this.searchString.toLowerCase();
        this.items.forEach((item) => {
          let from_send = item.from_send
            ? item.from_send.toLowerCase().includes(elm)
            : false;
          let to_send = item.to_send
            ? item.to_send.toLowerCase().includes(elm)
            : false;
          let durata = item.durata
            ? item.durata.toLowerCase().includes(elm)
            : false;
          let costo_pag = item.costo_pag
            ? item.costo_pag.toString().includes(elm)
            : false;
          let importo = item.importo
            ? item.importo.toString().includes(elm)
            : false;
          if (from_send || to_send || durata || costo_pag || importo) {
            this.itemFilter.push(item);
          }
        });
      } else {
        this.itemFilter = this.items;
      }
    },
    getDate(myTime) {
      return moment(myTime).format("DD-MM-YYYY");
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
