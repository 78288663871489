<template>
  <div id="Users">
    <MyBanner
      :text="$t('banner.accessDcPage')"
      v-if="$t('banner.accessDcPage') != ''"
    />
    <MyHeaderPage
      :title="$t('reports.maintenanceNotifications.title')"
      :subtitle="$t('reports.maintenanceNotifications.subTitle')"
    />
    <br /><br />
    <div id="TicketsList">
      <div class="listTicket">
        <!-- FILTRO -->
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--6-col">
            <div class="filterTickets">
              <div class="contentInputFilter">
                <div class="contentIcon">
                  <span class="material-icons">search</span>
                </div>
                <div class="contentInput">
                  <input
                    type="text"
                    :placeholder="$t('actions.text_search_placeholder')"
                    v-model="searchString"
                    @keyup="searchByString"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--3-col">
            <div class="cdl-input" style="margin-top: 0">
              <input
                type="date"
                name="dateFrom"
                class="not-empty"
                v-model="dateFrom"
                style="background-color: white"
                @change="getList()"
              />
              <label>{{ $t("reports.filter.dateFrom") }}</label>
              <span
                class="error"
                v-if="this.v$.dateFrom.$invalid && this.v$.dateFrom.$dirty"
              >
                {{ $t("reports.filter.dateFrom") }}
                {{ $t("reports.filter.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--3-col">
            <div class="cdl-input" style="margin-top: 0">
              <input
                type="date"
                name="dateTo"
                class="not-empty"
                v-model="dateTo"
                style="background-color: white"
                @change="getList()"
              />
              <label>{{ $t("reports.filter.dateTo") }}</label>
              <span
                class="error"
                v-if="this.v$.dateTo.$invalid && this.v$.dateTo.$dirty"
              >
                {{ $t("reports.filter.dateTo") }}
                {{ $t("reports.filter.isRequired") }}
              </span>
            </div>
          </div>
        </div>
        <!-- FINE FILTRO -->
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <br />
            <table>
              <thead>
                <tr>
                  <th>
                    {{ $t("reports.maintenanceNotifications.col.colOne") }}
                  </th>
                  <th>
                    {{ $t("reports.maintenanceNotifications.col.colTwo") }}
                  </th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.maintenanceNotifications.col.colThree") }}
                  </th>
                  <th v-if="!this.$IsMobile()">
                    {{ $t("reports.maintenanceNotifications.col.colFour") }}
                  </th>
                  <th v-if="!this.$IsMobile()"></th>
                </tr>
              </thead>
              <tbody
                v-if="
                  !this.$IsMobile() && this.viewLoader && itemFilter.length > 0
                "
              >
                <tr
                  v-for="(item, keyItem) in this.itemFilter"
                  :key="keyItem"
                  :class="{ odd: keyItem % 2 }"
                >
                  <td>{{ item.titolo }}</td>
                  <td>{{ item.data_creazione }}</td>
                  <td>{{ item.data_inizio }}</td>
                  <td>{{ item.data_fine }}</td>
                  <td>
                    <div class="cdlButton" @click="getDetails(item.id)">
                      {{ $t("tickets.details") }}
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody
                v-else-if="
                  this.$IsMobile() && this.viewLoader && itemFilter.length > 0
                "
              >
                <MobileRow
                  v-for="(item, keyItem) in this.itemFilter"
                  :key="keyItem"
                  :cols="['titolo', 'data_creazione']"
                  :item="item"
                >
                  <template #url-label>
                    <div class="cdlButton" @click="getDetails(item.id)">
                      {{ $t("tickets.details") }}
                    </div>
                  </template></MobileRow
                >
              </tbody>
              <tbody v-else-if="items.length == 0 && viewLoader">
                <tr>
                  <td colspan="4">
                    <span class="noTickets">{{ $t("reports.noResult") }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <MyLoading :viewLoader="this.viewLoader" type="" />
            </div>
          </div>
        </div>
        <br /><br />
        <Paginator
          v-if="total_page > 1"
          :total-page="total_page"
          :current-page="current_page"
          @change-page="changePage"
          @prev-page="beforePage"
          @next-page="nextPage"
        />
      </div>
    </div>

    <div id="MyModal" v-if="viewModal" style="padding-top: 50px">
      <div
        class="contentModal open success historical"
        style="width: 50%; max-height: 80vh; overflow-y: auto"
      >
        <span
          class="material-icons closeOrder"
          id="closeModalX"
          @click="ctrlModal()"
          >close</span
        >
        <div>
          <h3>{{ item.titolo }}</h3>
          <div v-html="item.testo"></div>
        </div>
        <div class="contentButton">
          <button class="cdlButton" @click="ctrlModal()">
            {{ $t("reports.historical.closeModal") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import MyLoading from "@/components/MyLoading.vue";
import MobileRow from "@/components/Tables/MobileRow.vue";
import "@/assets/scss/TicketsList.scss";
import moment from "moment";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Paginator from "@/components/Paginator.vue";

export default {
  name: "CMaintenanceNotifications",
  components: {
    MyBanner,
    MyHeaderPage,
    MyLoading,
    Paginator,
    MobileRow,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      dateFrom: { required },
      dateTo: { required },
    };
  },
  data() {
    return {
      viewLoader: false,
      items: [],
      itemFilter: [],
      dateFrom: moment().subtract(6, "months").format("DD-MM-YYYY"),
      dateTo: moment().format("DD-MM-YYYY"),
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      searchString: "",
      viewModal: false,
      item: {},
      total_page: 0,
      current_page: 1,
    };
  },
  methods: {
    async getList() {
      const result = await this.v$.$validate();
      if (result) {
        const self = this;
        this.axios
          .get(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
              "front/v1/report-maintenance/?from=" +
              this.dateFrom +
              "&to=" +
              this.dateTo +
              "&page=" +
              this.current_page,
            { headers: this.he }
          )
          .then((rls) => {
            this.items = rls.data.data;
            this.total_page = rls.data.last_page;
            this.current_page = rls.data.current_page;
            console.log(this.items);
            this.itemFilter = this.items;
            this.viewLoader = true;
          })
          .catch(function (err) {
            self.viewLoader = true;
            console.log(err);
          });
      }
    },
    searchByString() {
      this.itemFilter = [];
      if (this.searchString.length >= 3) {
        let elm = this.searchString.toLowerCase();
        this.items.forEach((item) => {
          let titolo = item.titolo
            ? item.titolo.toLowerCase().includes(elm)
            : false;
          let data_creazione = item.data_creazione
            ? item.data_creazione.toLowerCase().includes(elm)
            : false;
          let data_inizio = item.data_inizio
            ? item.data_inizio.toLowerCase().includes(elm)
            : false;
          let data_fine = item.data_fine
            ? item.data_fine.toLowerCase().includes(elm)
            : false;
          if (titolo || data_creazione || data_inizio || data_fine) {
            this.itemFilter.push(item);
          }
        });
      } else {
        this.itemFilter = this.items;
      }
    },
    getDetails(id) {
      const self = this;
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "front/v1/maintenance/?id=" +
            id,
          { headers: this.he }
        )
        .then((rls) => {
          this.item = rls.data;
          this.ctrlModal();
          this.viewLoader = true;
        })
        .catch(function (err) {
          self.viewLoader = true;
          console.log(err);
        });
    },
    ctrlModal() {
      this.viewModal = !this.viewModal;

      if (this.viewModal) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        document.getElementsByTagName("body")[0].classList.add("overflow");
      } else {
        document.getElementsByTagName("body")[0].classList.remove("overflow");
      }
    },
    changePage(page) {
      this.current_page = parseInt(page);
      this.getList();
    },
    nextPage() {
      if (this.current_page == this.total_page) {
        return;
      }
      this.current_page = parseInt(this.current_page) + 1;
      this.getList();
    },
    beforePage() {
      if (this.current_page == 1) {
        return;
      }
      this.current_page = parseInt(this.current_page) - 1;
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
