<template>
  <div id="Users">
    <MyBanner
      :text="$t('banner.accessDcPage')"
      v-if="$t('banner.accessDcPage') != ''"
    />
    <MyHeaderPage
      :title="$t('reports.paidInterventions.title')"
      :subtitle="$t('reports.paidInterventions.subTitle')"
    />
    <br /><br />
    <div id="TicketsList">
      <div class="listTicket">
        <!-- FILTRO -->
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--6-col">
            <div class="filterTickets">
              <div class="contentInputFilter">
                <div class="contentIcon">
                  <span class="material-icons">search</span>
                </div>
                <div class="contentInput">
                  <input
                    type="text"
                    :placeholder="$t('actions.text_search_placeholder')"
                    v-model="searchString"
                    @keyup="searchByString"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--3-col">
            <div class="cdl-input" style="margin-top: 0">
              <input
                type="date"
                name="dateFrom"
                class="not-empty"
                v-model="dateFrom"
                style="background-color: white"
                @change="getList()"
              />
              <label>{{ $t("reports.filter.dateFrom") }}</label>
              <span
                class="error"
                v-if="this.v$.dateFrom.$invalid && this.v$.dateFrom.$dirty"
              >
                {{ $t("reports.filter.dateFrom") }}
                {{ $t("reports.filter.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--3-col">
            <div class="cdl-input" style="margin-top: 0">
              <input
                type="date"
                name="dateTo"
                class="not-empty"
                v-model="dateTo"
                style="background-color: white"
                @change="getList()"
              />
              <label>{{ $t("reports.filter.dateTo") }}</label>
              <span
                class="error"
                v-if="this.v$.dateTo.$invalid && this.v$.dateTo.$dirty"
              >
                {{ $t("reports.filter.dateTo") }}
                {{ $t("reports.filter.isRequired") }}
              </span>
            </div>
          </div>
        </div>
        <!-- FINE FILTRO -->
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <br />
            <table>
              <thead>
                <tr>
                  <th>{{ $t("reports.paidInterventions.col.colOne") }}</th>
                  <th>{{ $t("reports.paidInterventions.col.colTwo") }}</th>
                  <th>{{ $t("reports.paidInterventions.col.colThree") }}</th>
                  <th>{{ $t("reports.paidInterventions.col.colFour") }}</th>
                  <th>{{ $t("reports.paidInterventions.col.colFive") }}</th>
                  <th>{{ $t("reports.paidInterventions.col.colSix") }}</th>
                  <th>{{ $t("reports.paidInterventions.col.colSeven") }}</th>
                  <th>{{ $t("reports.paidInterventions.col.colEight") }}</th>
                  <th>{{ $t("reports.paidInterventions.col.colNine") }}</th>
                  <th v-if="false"></th>
                </tr>
              </thead>
              <tbody v-if="this.viewLoader && itemFilter.length > 0">
                <tr
                  v-for="(item, keyItem) in this.itemFilter"
                  :key="keyItem"
                  :class="{ odd: keyItem % 2 }"
                >
                  <td>
                    {{
                      $t("reports.paidInterventions.type." + item.ticket_url)
                    }}
                  </td>
                  <td>{{ item.ticket_esteso }}</td>
                  <td>{{ item.customer_ref }}</td>
                  <td>{{ item.data_apertura }}</td>
                  <td>
                    {{
                      item.po_number && item.po_number != ""
                        ? item.po_number
                        : "-"
                    }}
                  </td>
                  <td>{{ item.doc_type }}</td>
                  <td>{{ item.doc_number }}</td>
                  <td>{{ item.date_total }}</td>
                  <td>
                    <strong v-if="item.type_price"
                      >{{
                        $t("reports.paidInterventions." + item.type_price)
                      }}:</strong
                    >
                    {{ item.total }}
                  </td>
                </tr>
              </tbody>
              <tbody v-if="items.length == 0 && viewLoader">
                <tr>
                  <td colspan="4">
                    <span class="noTickets">{{ $t("reports.noResult") }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <MyLoading :viewLoader="this.viewLoader" type="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import MyLoading from "@/components/MyLoading.vue";
import "@/assets/scss/TicketsList.scss";
import moment from "moment";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "CPaidInterventions",
  components: {
    MyBanner,
    MyHeaderPage,
    MyLoading,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      dateFrom: { required },
      dateTo: { required },
    };
  },
  data() {
    return {
      viewLoader: false,
      items: [],
      itemFilter: [],
      // items: [
      //   {
      //       "id": 240,
      //       "ticket_esteso": "2019122067000084",
      //       "customer_ref": "Davide De Luca",
      //       "data_apertura": "2019-12-20 16:17:38",
      //       "po_number": "",
      //       "ticket_url": "dc-remote-hands",
      //       "doc_type": "Fattura",
      //       "doc_number": "232",
      //       "total": "200,00",
      //       "type_price": null,
      //       "date_total": "2020-01-31"
      //   },
      //   {
      //       "id": 240,
      //       "ticket_esteso": "2019122067000084",
      //       "customer_ref": "Ciccio De Luca",
      //       "data_apertura": "2020-12-20 16:17:38",
      //       "po_number": "",
      //       "ticket_url": "dc-cross-connect",
      //       "doc_type": "Credito",
      //       "doc_number": "232",
      //       "total": "300,00",
      //       "type_price": null,
      //       "date_total": "2022-01-31"
      //   }
      // ],
      // itemFilter: [
      //   {
      //       "id": 240,
      //       "ticket_esteso": "2019122067000084",
      //       "customer_ref": "Davide De Luca",
      //       "data_apertura": "2019-12-20 16:17:38",
      //       "po_number": "",
      //       "ticket_url": "dc-remote-hands",
      //       "doc_type": "Fattura",
      //       "doc_number": "232",
      //       "total": "200,00",
      //       "type_price": null,
      //       "date_total": "2020-01-31"
      //   },
      //   {
      //       "id": 240,
      //       "ticket_esteso": "2019122067000084",
      //       "customer_ref": "Ciccio De Luca",
      //       "data_apertura": "2022-12-20 16:17:38",
      //       "po_number": "",
      //       "ticket_url": "dc-cross-connect",
      //       "doc_type": "Credito",
      //       "doc_number": "232",
      //       "total": "300,00",
      //       "type_price": null,
      //       "date_total": "2022-01-31"
      //   }
      // ],
      dateFrom: moment().subtract(6, "months").format("DD-MM-YYYY"),
      dateTo: moment().format("DD-MM-YYYY"),
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      lang: "en",
      searchString: "",
    };
  },
  methods: {
    async getList() {
      const result = await this.v$.$validate();
      if (result) {
        const self = this;
        this.axios
          .get(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
              "front/v1/report-paid-support/?from=" +
              this.dateFrom +
              "&to=" +
              this.dateTo,
            { headers: this.he }
          )
          .then((rls) => {
            this.items = rls.data;
            this.itemFilter = this.items;
            this.viewLoader = true;
          })
          .catch(function (err) {
            self.viewLoader = true;
            console.log(err);
          });
      }
    },
    searchByString() {
      this.itemFilter = [];
      if (this.searchString.length >= 3) {
        let elm = this.searchString.toLowerCase();
        this.items.forEach((item) => {
          let ticket_url = item.ticket_url
            ? item.ticket_url.toLowerCase().includes(elm)
            : false;
          let ticket_esteso = item.ticket_esteso
            ? item.ticket_esteso.toLowerCase().includes(elm)
            : false;
          let customer_ref = item.customer_ref
            ? item.customer_ref.toLowerCase().includes(elm)
            : false;
          let data_apertura = item.data_apertura
            ? item.data_apertura.toLowerCase().includes(elm)
            : false;
          let po_number = item.po_number
            ? item.po_number.toLowerCase().includes(elm)
            : false;
          let doc_type = item.doc_type
            ? item.doc_type.toLowerCase().includes(elm)
            : false;
          let doc_number = item.doc_number
            ? item.doc_number.toLowerCase().includes(elm)
            : false;
          let date_total = item.date_total
            ? item.date_total.toLowerCase().includes(elm)
            : false;
          let type_price = item.type_price
            ? item.type_price.toLowerCase().includes(elm)
            : false;
          let total = item.total
            ? item.total.toLowerCase().includes(elm)
            : false;
          if (
            ticket_url ||
            ticket_esteso ||
            customer_ref ||
            data_apertura ||
            po_number ||
            doc_type ||
            doc_number ||
            date_total ||
            type_price ||
            total
          ) {
            this.itemFilter.push(item);
          }
        });
      } else {
        this.itemFilter = this.items;
      }
    },
    // getPrice( item ) {
    //   let text = '';
    //   switch( type_price ) {
    //     case 'ATTIVAZIONE':
    //       text = "reports.paidInterventions."
    //   }
    // }
  },
  mounted() {
    this.getList();
    this.lang = this.$i18n.locale;
  },
};
</script>
