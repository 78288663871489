<template>
  <div id="Users">
    <MyBanner
      :text="$t('banner.accessDcPage')"
      v-if="$t('banner.accessDcPage') != ''"
    />
    <MyHeaderPage
      :title="$t('reports.cloud.title')"
      :subtitle="$t('reports.cloud.subTitle')"
    />
    <br /><br />
    <div id="TicketsList">
      <div class="listTicket">
        <!-- FILTRO -->
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--6-col">
            <div class="filterTickets">
              <div class="contentInputFilter">
                <div class="contentIcon">
                  <span class="material-icons">search</span>
                </div>
                <div class="contentInput">
                  <input
                    type="text"
                    :placeholder="$t('actions.text_search_placeholder')"
                    v-model="searchString"
                    @keyup="searchByString"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--3-col">
            <div class="cdl-input" style="margin-top: 0">
              <input
                type="date"
                name="dateFrom"
                class="not-empty"
                v-model="dateFrom"
                style="background-color: white"
                @change="getList()"
              />
              <label>{{ $t("reports.filter.dateFrom") }}</label>
              <span
                class="error"
                v-if="this.v$.dateFrom.$invalid && this.v$.dateFrom.$dirty"
              >
                {{ $t("reports.filter.dateFrom") }}
                {{ $t("reports.filter.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--3-col">
            <div class="cdl-input" style="margin-top: 0">
              <input
                type="date"
                name="dateTo"
                class="not-empty"
                v-model="dateTo"
                style="background-color: white"
                @change="getList()"
              />
              <label>{{ $t("reports.filter.dateTo") }}</label>
              <span
                class="error"
                v-if="this.v$.dateTo.$invalid && this.v$.dateTo.$dirty"
              >
                {{ $t("reports.filter.dateTo") }}
                {{ $t("reports.filter.isRequired") }}
              </span>
            </div>
          </div>
        </div>
        <!-- FINE FILTRO -->
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <br />
            <table>
              <thead>
                <tr>
                  <th>{{ $t("reports.cloud.col.colOne") }}</th>
                  <th>{{ $t("reports.cloud.col.colTwo") }}</th>
                  <th>{{ $t("reports.cloud.col.colThree") }}</th>
                  <th>{{ $t("reports.cloud.col.colFour") }}</th>
                  <th>{{ $t("reports.cloud.col.colFive") }}</th>
                </tr>
              </thead>
              <tbody v-if="this.viewLoader && itemFilter.length > 0">
                <tr
                  v-for="(item, keyItem) in this.itemFilter"
                  :key="keyItem"
                  :class="{ odd: keyItem % 2 }"
                >
                  <td>{{ item.data_charge }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.usage_hours }}</td>
                  <td>{{ item.usage_pph }}</td>
                  <td>{{ item.usage_charge }}</td>
                </tr>
              </tbody>
              <tbody v-if="items.length == 0 && viewLoader">
                <tr>
                  <td colspan="4">
                    <span class="noTickets">{{ $t("reports.noResult") }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <MyLoading :viewLoader="this.viewLoader" type="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import MyLoading from "@/components/MyLoading.vue";
import "@/assets/scss/TicketsList.scss";
import moment from "moment";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "CReportCloud",
  components: {
    MyBanner,
    MyHeaderPage,
    MyLoading,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      dateFrom: { required },
      dateTo: { required },
    };
  },
  data() {
    return {
      viewLoader: false,
      items: [],
      itemFilter: [],
      // items: [
      //         {
      //             "id": 0,
      //             "domainid": "467dfca3-20d1-483c-b0fb-aba624f87533",
      //             "data_charge": "2023-11-15",
      //             "description": "Subnet dedicata 95.157.81.0/24",
      //             "usage_hours": 24,
      //             "usage_pph": "0.350000",
      //             "usage_charge": "8.400000"
      //         },
      //         {
      //             "id": 2,
      //             "domainid": "467dfca3-20d1-483c-b0fb-aba624f87533",
      //             "data_charge": "2023-11-15",
      //             "description": "KVM - Allocated VM usage for DRAYTEK-ACS3 (i-6-1316-VM) (bfba8e93-5374-4163-ba09-cccae35b437e) using service offering STD - 4G, 2C (0a89332c-e8cb-4767-ba90-414326e563a0) and template Debian 11 - pwd:32JUhHb3 (d83e3f51-141e-4059-9453-5e83d47a748b)",
      //             "usage_hours": 24,
      //             "usage_pph": "0.025000",
      //             "usage_charge": "0.600000"
      //         },
      // ],
      // itemFilter: [
      //         {
      //             "id": 0,
      //             "domainid": "467dfca3-20d1-483c-b0fb-aba624f87533",
      //             "data_charge": "2023-11-15",
      //             "description": "Subnet dedicata 95.157.81.0/24",
      //             "usage_hours": 24,
      //             "usage_pph": "0.350000",
      //             "usage_charge": "8.400000"
      //         },
      //         {
      //             "id": 2,
      //             "domainid": "467dfca3-20d1-483c-b0fb-aba624f87533",
      //             "data_charge": "2023-11-15",
      //             "description": "KVM - Allocated VM usage for DRAYTEK-ACS3 (i-6-1316-VM) (bfba8e93-5374-4163-ba09-cccae35b437e) using service offering STD - 4G, 2C (0a89332c-e8cb-4767-ba90-414326e563a0) and template Debian 11 - pwd:32JUhHb3 (d83e3f51-141e-4059-9453-5e83d47a748b)",
      //             "usage_hours": 24,
      //             "usage_pph": "0.025000",
      //             "usage_charge": "0.600000"
      //         },
      // ],
      dateFrom: moment().subtract(6, "months").format("DD-MM-YYYY"),
      dateTo: moment().format("DD-MM-YYYY"),
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      searchString: "",
    };
  },
  methods: {
    async getList() {
      const result = await this.v$.$validate();
      if (result) {
        const self = this;
        this.axios
          .get(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
              "front/v1/report-cloud/?from=" +
              this.dateFrom +
              "&to=" +
              this.dateTo,
            { headers: this.he }
          )
          .then((rls) => {
            // console.log(rls);
            this.items = rls.data;
            this.itemFilter = this.items;
            this.viewLoader = true;
          })
          .catch(function (err) {
            self.viewLoader = true;
            console.log(err);
          });
      }
    },
    searchByString() {
      this.itemFilter = [];
      if (this.searchString.length >= 3) {
        let elm = this.searchString.toLowerCase();
        this.items.forEach((item) => {
          let data_charge = item.data_charge
            ? item.data_charge.toLowerCase().includes(elm)
            : false;
          let description = item.description
            ? item.description.toLowerCase().includes(elm)
            : false;
          let usage_pph = item.usage_pph
            ? item.usage_pph.toLowerCase().includes(elm)
            : false;
          let usage_charge = item.usage_charge
            ? item.usage_charge.toLowerCase().includes(elm)
            : false;
          if (data_charge || description || usage_pph || usage_charge) {
            this.itemFilter.push(item);
          }
        });
      } else {
        this.itemFilter = this.items;
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
