<template>
  <div id="Solutions">
    <MyHeaderPage :title="$t('crossConnects.title')" />
    <br /><br />
    <div id="LinesList">
      <div class="listLines">
        <div class="mdl-grid" v-if="this.tab_index === 1">
          <div class="mdl-cell mdl-cell--4-col">
            <TextSearchInput
              @search="this.searchByString"
              @reset="this.resetSearchByString"
            />
          </div>
          <div class="mdl-cell mdl-cell--2-col"></div>
          <div
            class="mdl-cell mdl-cell--3-col mdl-cell--12-col-phone"
            v-show="
              ctrlPerms(['incident_lista', 'change_request_list']) &&
              tab_index !== 2
            "
          >
            <div class="helpText alignRight">
              <p class="textXL">{{ $t("help.generic.btnLabel") }}</p>
            </div>
          </div>

          <div class="helpRequestButtons">
            <HelpRequestBtn
              v-if="ctrlPerms(['incident_lista'])"
              :component-name="'CrossConnectGenericIncidentForm'"
              :asset-type="'cross-connects'"
              :icon="'warning'"
              :label="$t('help.incident.formTitle')"
            />
            <HelpRequestBtn
              v-if="ctrlPerms(['change_request_list'])"
              :component-name="'CrossConnectGenericChangeRequestForm'"
              :asset-type="'cross-connects'"
              :icon="'person'"
              :label="$t('help.changeRequest.formTitle')"
            />
          </div>
        </div>
        <div class="mdl-grid" v-else>
          <div class="mdl-cell mdl-cell--4-col">
            <TextSearchInput
              @search="this.searchByString"
              @reset="this.resetSearchByString"
            />
          </div>
          <div class="mdl-cell mdl-cell--2-col"></div>
        </div>
        <TabContainer>
          <template #tabs>
            <Tab
              id="tabOne"
              :label="$t('tabs.listLabel')"
              @click="this.setTab(1)"
              :class="{ 'is-active': this.tab_index === 1 }"
            ></Tab>
            <Tab
              v-if="
                this.$ctrlPermissions([
                  'incident_list',
                  'change_request_list',
                  'help_ticket_list',
                ])
              "
              id="tabTwo"
              :label="$t('tabs.ticketListLabel')"
              @click="this.setTab(2)"
              :class="{ 'is-active': this.tab_index === 2 }"
            ></Tab>
          </template>
          <template #content>
            <div
              class="mdl-tabs__panel"
              :class="{ 'is-active': this.tab_index === 1 }"
              v-show="this.tab_index === 1"
            >
              <List
                :list="filtered"
                :view-loader="this.viewLoader"
                :view-error="this.viewError"
                :total-page="this.total_page"
              />
            </div>
            <div
              class="mdl-tabs__panel"
              :class="{ 'is-active': this.tab_index === 2 }"
              v-show="this.tab_index === 2"
            >
              <XConnTicketList
                :list="filtered"
                :view-loader="this.viewLoader"
                :view-error="this.viewError"
                :total-page="this.total_page"
                :total-count="this.total_count"
                @change-page="this.onChangePage"
                @refresh="getTicketList"
              />
            </div>
            <div class="mdl-grid" v-show="!this.viewLoader">
              <div class="mdl-cell mdl-cell--12-col" style="text-align: center">
                <MyLoading :viewLoader="this.viewLoader" type="" />
              </div>
            </div>
          </template>
        </TabContainer>
      </div>
    </div>
  </div>
</template>
<style scoped>
.help-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.helpText {
  margin-bottom: 10px;
}
.helpRequestButtons {
  display: flex;
  gap: 10px;
}
</style>
<script>
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import MyLoading from "@/components/MyLoading.vue";
import TabContainer from "@/components/Utils/TabContainer.vue";
import Tab from "@/components/Utils/Tab.vue";
import TextSearchInput from "@/components/Search/TextSearch/TextSearchInput.vue";
import List from "@/components/CrossConnect/List.vue";
import XConnTicketList from "@/components/CrossConnect/TicketList.vue";
import { filterDataByColumns } from "@/services/SearchService";
import HelpRequestBtn from "@/components/Tables/HelpRequestBtn.vue";

export default {
  name: "CrossConnect",
  components: {
    HelpRequestBtn,
    MyLoading,
    MyHeaderPage,
    TabContainer,
    Tab,
    TextSearchInput,
    List,
    XConnTicketList,
  },
  data() {
    return {
      items: [],
      filtered: [],
      search_columns: ["building", "address", "name"],
      tab_index: 1,
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      viewLoader: true,
      viewError: false,
      page_size: 10,
      total_page: 1,
      total_count: 0,
      current_page: 0,
      search_by_string_key: "",
    };
  },
  methods: {
    ctrlPerms(perms) {
      return this.$ctrlPermissions(perms);
    },
    searchByString(keyword) {
      this.search_by_string_key = keyword;

      this.filtered = filterDataByColumns(
        this.items,
        this.search_columns,
        this.search_by_string_key
      );

      console.log(`${this.filtered.length} items found for ${keyword}`);

      // if no results are found in cache refresh data with search params
      if (!this.filtered.length) {
        this.filtered = [];
      }

      this.total_count = this.items.length;
    },
    resetSearchByString() {
      this.search_by_string_key = "";
      if (this.tab_index === 1) {
        this.getList();
      } else {
        this.getTicketList();
      }
    },
    setTab(index) {
      this.tab_index = index;
      this.search_by_string_key = "";
      if (index === 1) {
        this.getList();
      } else {
        this.search_columns = ["subject", "cf_sold_solution"];
        this.getTicketList();
      }
    },
    getList() {
      this.viewLoader = true;
      this.filtered = [];
    },
    getTicketList() {
      const URL = "tickets/v1/ticket-list";
      const TICKET_TYPE =
        "Incident,ChangeRequest,TechAssist,CrossConnectsSupport";

      let params = {
        asset_type: "cross-connects",
        page_size: this.page_size,
        service: TICKET_TYPE,
        page: this.current_page,
      };

      this.viewLoader = false;
      this.total_count = 0;
      const self = this;

      if (this.search_by_string_key.length > 3) {
        params = {
          ...params,
          search_by: this.search_by_string_key,
          filter_by: this.columns,
        };
      }

      this.axios
        .post(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + URL, params, {
          headers: this.he,
        })
        .then((response) => {
          self.items = response.data.data;
          self.filtered = response.data.data;
          self.total_count = response.data.count;
          self.viewLoader = true;
        })
        .catch(function (err) {
          //TODO: FIX THIS IN BE
          // manage 404 code if no tickets are found
          if (err.response.status === 404) {
            self.viewLoader = true;
            self.items = [];
            self.filtered = [];
            return;
          }
          self.viewLoader = true;
          self.viewError = true;
          self.messageError = err.message;
          setTimeout(() => {
            self.viewError = false;
          }, 5000);
        });
    },
    onChangePage(page) {
      this.current_page = page;
      if (this.tab_index === 2) {
        this.getTicketList();
      }
    },
  },
};
</script>
