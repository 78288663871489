import { defineStore } from "pinia";
import moment from "moment";
import axios from "axios";
import { useUserStore } from "@/stores/UserStore";

export const useAccessDcStore = defineStore("AccessDcStore", {
  state: () => {
    return {
      form: {
        dc: {
          dc_name: null,
          address: null,
          fromTime: null,
          toTime: null,
          apparati: "no",
          warehouse: "no",
          manutenzione: false,
          keys: [],
          noteKey: null,
          racks: [],
          users: [],
          mop: null,
          ref: {
            ref_name: null,
            ref_surname: null,
            ref_phone: null,
            ref_email: null,
          },
        },
        coworking: {
          desks: 0,
          fromTime: null,
          toTime: null,
          users: [],
        },
        parking: {
          fromTime: null,
          toTime: null,
          users: [],
          cars: [],
        },
        typeAccess: [],
        dateAccess: null,
      },
      lang: null,
      tokenApicore: null,
      isCompleted: false,
      steps: 1,
      maxSeatsDeskCoworking: 6,
      gammaId: 0,
      mopTmp: [],
      isBio: false,
      usersBio: [],
      onlyRead: false,
      switchKey: false,
    };
  },
  getters: {
    // length: () => {
    //   return cart.length;
    // }
  },
  actions: {
    getTypeAccessName() {
      // return "da fare";
      let nameAccess = [];
      if (localStorage.getItem("cdlAccessDcSteps")) {
        let steps = JSON.parse(localStorage.getItem("cdlAccessDcSteps"));
        if (steps.steps) {
          if (steps.steps.indexOf("WhereAndWhat") > -1) {
            // ACCESSO SALE
            nameAccess.push("accessDc.accessTypeOne");
            this.typeAccess = 1;
          }
          if (steps.steps.indexOf("DeskAndHours") > -1) {
            // ACCESSO COWORKING
            nameAccess.push("accessDc.accessTypeTwo");
            this.typeAccess = 2;
          }
          if (
            steps.steps.indexOf("BookParking") > -1 &&
            nameAccess.length == 0
          ) {
            // ACCESSO AUTO
            nameAccess.push("accessDc.accessTypeThree");
            this.typeAccess = 3;
          }
        }
      }
      return nameAccess;
    },
    getAccessWhen() {
      if (!this.form.dateAccess) {
        return "";
      }
      let nameDay = moment(
        new Date(this.form.dateAccess),
        "DD-MM-YYYY",
        this.lang,
        true
      ).format("dddd");
      let date = moment(
        new Date(this.form.dateAccess),
        "DD-MM-YYYY",
        this.lang,
        true
      ).format("LL");
      return nameDay.charAt(0).toUpperCase() + nameDay.slice(1) + " " + date;
    },
    resetAccess() {
      this.form.dc.dc_name = null;
      this.form.dc.address = null;
      this.form.dc.fromTime = null;
      this.form.dc.toTime = null;
      this.form.dc.apparati = "no";
      this.form.dc.warehouse = "no";
      this.form.dc.manutenzione = "no";
      this.form.dc.keys = [];
      this.form.dc.noteKey = null;
      this.form.dc.racks = [];
      this.form.dc.users = [];
      this.form.dc.mop = null;
      this.form.dc.ref.ref_name = null;
      this.form.dc.ref.ref_surname = null;
      this.form.dc.ref.ref_phone = null;
      this.form.dc.ref.ref_email = null;

      this.form.coworking = {
        desks: 0,
        fromTime: null,
        toTime: null,
        users: [],
      };

      this.form.parking = {
        fromTime: null,
        toTime: null,
        users: [],
        cars: [],
      };

      this.form.typeAccess = [];
      this.form.dateAccess = null;

      this.lang = null;
      this.tokenApicore = null;
      this.isCompleted = false;
      this.steps = 1;
      this.maxSeatsDeskCoworking = 6;
      this.mopTmp = [];
      this.onlyRead = false;
      this.switchKey = false;
    },
    async getGammaId() {
      let user = useUserStore().user;
      if (user.account) {
        this.gammaId = user.account.id;
      }
    },
    generateTokenApicore() {
      // Token Apicore
      let he = {
        Accept: "application/json",
      };

      let input = {
        grant_type: "client_credentials",
        client_id: 4,
        client_secret: "AB8sLiGGjyV7P41npd94wPOa2z4KwptgMb5OWRBS",
      };

      axios
        .post("https://apicore.cdlan.net/oauth/token", input, { headers: he })
        .then((rls) => {
          this.tokenApicore = rls.data.access_token;
          console.log(rls.data.access_token);
        });
    },
    generateSteps(withCars) {
      let setSteps = {
        steps: ["DeskAndHours"],
        listSteps: [{ icon: "MyStep7", title: "accessDc.titleStep7" }],
      };
      if (withCars > 0) {
        setSteps.steps.push("BookParking");
        setSteps.listSteps.push({
          icon: "MyStep8",
          title: "accessDc.titleStep8",
        });
      }
      localStorage.setItem("cdlAccessDcSteps", JSON.stringify(setSteps));
    },
  },
});
